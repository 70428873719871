import axios from "axios";

export default function () {
    return {
        async updateCurrentPosition(artisanId, currentLatitude, currentLongitude) {
            try {
                await axios.patch(process.env.REACT_APP_API_URL + `/service-user/guest/artisans/${artisanId}/current_position`, {
                    artisan: {
                        current_latitude: currentLatitude,
                        current_longitude: currentLongitude
                    }
                });
            } catch (e) {
            }
        }
    };
}