import {BrowserRouter, Route, Routes} from "react-router-dom";
import QualityInspection from "../view/tracking/QualityInspection";
import UpdateQuotation from "../view/tracking/UpdateQuotation";
import E404 from "../view/error/E404";

export default function () {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="*" element={<E404/>}/>
                <Route path="/i/:token" element={<QualityInspection/>}/>
                <Route path="/i/:token/update/quotation" element={<UpdateQuotation/>}/>
            </Routes>
        </BrowserRouter>
    );
}