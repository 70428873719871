import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    qualityInspection: {
        id: null,
        quotation_validate: false,
        arrived_at: null,
        left_at: null,
        current_step: null,
        current_sub_step: null,
        completed_works: null,
        delivered_without_reservation: null,
        delivered_comment: null,
        certificate_client_price_signature_id: null,
        certificate_delivery_signature_id: null
    },
    intervention: {
        id: null,
        status: null,
        token: null,
        appointment: null,
        artisan_price: null,
        client_price: null,
        created_at: null,
        company_comment: null
    },
    order: {
        id: null,
        max_price: null,
        order_comment: null,
        prescriber_id: null,
        status: null,
        vat: null,
        work_id: null,
        created_at: null,
        sell_price_main_work: 0,
        sell_price_shifting: 0,
        admin_phone: null
    },
    client: {
        id: null,
        name: null,
        phone_1: null,
        phone_2: null
    },
    address: {
        id: null,
        city: null,
        postcode: null,
        line_1: null,
        line_2: null,
        line_3: null,
        lat: null,
        lng: null
    },
    quotation: {
        vat: null,
    },
    quotationItems: [],
    orderItems: []
}

export const app = createSlice({
    name: 'app',
    initialState,
    reducers: {
        init: (state, action) => {
            state.qualityInspection.id = action.payload.id;
            state.qualityInspection.quotation_validate = action.payload.quotation_validate;
            state.qualityInspection.arrived_at = action.payload.arrived_at;
            state.qualityInspection.left_at = action.payload.left_at;
            state.qualityInspection.current_step = action.payload.current_step;
            state.qualityInspection.current_sub_step = action.payload.current_sub_step;
            state.qualityInspection.completed_works = action.payload.completed_works;
            state.qualityInspection.delivered_comment = action.payload.delivered_comment;
            state.qualityInspection.delivered_without_reservation = action.payload.delivered_without_reservation;
            state.qualityInspection.certificate_client_price_signature_id = action.payload.certificate_client_price_signature_id;
            state.qualityInspection.certificate_delivery_signature_id = action.payload.certificate_delivery_signature_id;
            state.intervention = action.payload.intervention;
            state.order = action.payload.intervention.order;
            state.orderItems = action.payload.intervention.order.order_items;
            state.client = action.payload.intervention.order.client;
            state.address = action.payload.intervention.order.address;
            state.quotation = action.payload.intervention.quotation;
            state.quotationItems = action.payload.intervention.quotation !== null
                ? action.payload.intervention.quotation.items : [];
        },
        updateInterventionCompanyComment: (state, action) => {
            state.intervention.company_comment = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {init, updateInterventionCompanyComment} = app.actions;

export default app.reducer;
