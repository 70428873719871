import {useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";

export default function ({nextStep}) {
    const demo = useSelector(state => state.demo);
    const signatureHelpButtonInterval = useRef();
    const skipSignatureButtonInterval = useRef();
    const [stepCompleted, setStepCompleted] = useState(false);

    useEffect(() => {
        play();
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(signatureHelpButtonInterval.current);
            clearInterval(skipSignatureButtonInterval.current);
        }
    }, []);

    const play = () => {
        setTimeout(async () => {
            window.sound.src = "/demo/step-10.1.mp3";
            await window.sound.play();
        }, 500);

        launchSignatureHelpButton();
    }


    const launchSignatureHelpButton = () => {
        setTimeout(() => {
            signatureHelpButtonInterval.current = setInterval(() => {
                const signatureHelpButton = document.getElementById("signature-help-button");

                if (signatureHelpButton.style.background === "" || signatureHelpButton.style.background === "rgb(0, 127, 254)") {
                    signatureHelpButton.style.background = "#eb4435";
                    signatureHelpButton.style.color = "white";
                } else {
                    signatureHelpButton.style.background = "rgb(0, 127, 254)";
                    signatureHelpButton.style.color = "white";
                }

            }, 500);
        }, 1000);

        setTimeout(() => {
            clearInterval(signatureHelpButtonInterval.current);

            const signatureHelpButton = document.getElementById("signature-help-button");

            signatureHelpButton.style.background = "rgb(0, 127, 254)";
            signatureHelpButton.style.color = "white";

            setTimeout(() => {
                signatureHelpButton.style.background = "yellow";

                setTimeout(() => {
                    signatureHelpButton.click();

                    setTimeout(() => {
                        launchSkipSignatureButton();
                    }, 1000);
                }, 300);
            }, 1000);
        }, 2000);
    }

    const launchSkipSignatureButton = () => {
        const skipSignatureButton = document.getElementById("skip-signature-button");

        skipSignatureButton.focus();

        setTimeout(() => {
            skipSignatureButtonInterval.current = setInterval(() => {
                const skipSignatureButton = document.getElementById("skip-signature-button");

                if (skipSignatureButton.style.background === "" || skipSignatureButton.style.background === "rgb(0, 127, 254)") {
                    skipSignatureButton.style.background = "#eb4435";
                    skipSignatureButton.style.color = "white";
                } else {
                    skipSignatureButton.style.background = "rgb(0, 127, 254)";
                    skipSignatureButton.style.color = "white";
                }
            }, 500);

            setTimeout(() => {
                clearInterval(skipSignatureButtonInterval.current);

                const skipSignatureButton = document.getElementById("skip-signature-button");

                skipSignatureButton.style.background = "rgb(0, 127, 254)";
                skipSignatureButton.style.color = "white";

                setTimeout(() => {
                    skipSignatureButton.style.background = "yellow";

                    setTimeout(() => {
                        skipSignatureButton.click();
                        nextStep();
                    }, 2000);
                }, 1000);
            }, 2000);
        }, 1000);
    }


    if (stepCompleted) return null;

    return (
        <div className="bg-white/30 fixed z-50 h-full w-full max-w-2xl">

        </div>
    );
}