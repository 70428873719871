import React, {useRef, useState} from "react";
import NextStepButton from "./NextStepButton";
import mixin from "../../../mixin/mixin";
import useStore from "../../../use/useStore";
import InfoIcon from "../../util/icon/InfoIcon";
import PictureIcon from "../../util/icon/PictureIcon";
import CloseIcon from "../../util/icon/CloseIcon";
import useAlert from "../../../use/useAlert";
import enums from "../../../enum/enums";
import useApi from "../../../api/useApi";

function StepPictureBefore() {
    const api = useApi();
    const store = useStore();
    const alert = useAlert();
    const cameraInput = useRef();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [pictureBefore, setPictureBefore] = useState([]);

    const MAX_AMOUNT_PICTURE = 3;

    const openCamera = () => {

        if (pictureBefore.length >= MAX_AMOUNT_PICTURE) {
            alert.launch("Vous ne pouvez sélectionner que " + MAX_AMOUNT_PICTURE + " photos au maximum", "warning");
            return;
        }

        cameraInput.current.click();
    }

    const imageUploaded = async (file) => {
        if (file === null || file === undefined) {
            alert.launch("Aucune image", "warning");
            return;
        }

        if (file.type.startsWith("image")) {

            const data = await mixin.compressFile(file, {
                size: 1,
                quality: .5,
                rotate: false
            });

            let contentType = file.type;
            let content = data[0].data;

            setPictureBefore((prevState) => ([...prevState, {"content": content, "content-type": contentType}]));

        } else {
            const reader = new FileReader();

            reader.onload = ((f) => (e) => {
                let contentType = file.type;
                let content = e.target.result.replace("data:" + contentType + ";base64,", "");

                setPictureBefore((prevState) => ([...prevState, {"content": content, "content-type": contentType}]));
            })(file);

            reader.readAsDataURL(file);
        }
    }

    const deletePicture = (index) => {
        const picturesArray = pictureBefore;
        picturesArray.splice(index, 1);

        setPictureBefore([...picturesArray]);
    }

    const nextStep = async () => {
        if (pictureBefore.length === 0) {
            alert.launch("Merci de prendre la photo avant intervention", "warning");
            return;
        }

        const payload = {
            uploaded_file: {
                quality_inspection_picture_before_files: pictureBefore
            },
            quality_inspection: {
                current_step: enums.qualityInspection.STEP_PICTURE_BEFORE
            },
        }

        setButtonLoading(true);
        const isSuccess = await api.qualityInspection.nextStep(payload);

        if (isSuccess) {
            await store.init();
            return;
        }

        setButtonLoading(false);
        alert.launch("Une erreur s'est produite", "error");

    }

    return (
        <div>
            <div>

                <div className={'my-2 p-2 flex bg-gray-100 text-blue-1 rounded items-center mt-10'}>
                    <InfoIcon/>
                    <div className="leading-tight">
                        Une fois chez le sociétaire, je prends des photos avant d'intervenir.
                    </div>
                </div>
                <div className={'my-5 p-2 flex-column'}>
                    <div className={'flex flex-col my-2'}>
                        <button
                            className="bg-blue-600 text-white p-3 flex justify-center w-full"
                            id="picture-before-button"
                            onClick={() => openCamera()}
                        >
                            <input
                                ref={cameraInput}
                                className="custom-file-input cursor-pointer"
                                type="file"
                                accept="image/*"
                                style={{display: "none"}}
                                name="pictureBefore"
                                onChange={(event) => {
                                    imageUploaded(event.target.files[0]);
                                    event.target.value = null
                                }}
                            />
                            <PictureIcon/>
                            <label>
                                Prendre une photo ({MAX_AMOUNT_PICTURE} maximum)
                            </label>
                        </button>
                    </div>

                    {
                        pictureBefore.map((picture, index) => {
                            if (picture === null || picture["content-type"] === null || picture.content === null) {
                                return null;
                            }

                            return (
                                <div key={index} className="picture">
                                    <img
                                        src={`data:${picture['content-type']};base64,${picture.content}`}
                                    />
                                    <button
                                        className="close text-red-1"
                                        onClick={() => deletePicture(index)}
                                    >
                                        <CloseIcon size={12}/>
                                    </button>
                                </div>
                            )
                        })
                    }

                </div>

                <NextStepButton
                    title={'Suivant'}
                    callback={nextStep} loading={buttonLoading}
                    style="bg-blue-900"
                />
            </div>
        </div>
    )
}

export default StepPictureBefore;
