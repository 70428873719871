import React, {useEffect, useState} from "react";

export default function ({callBack}) {
    const [selected, setSelected] = useState("10");
    const [showExplanation, setShowExplanation] = useState(false);

    const change = (value) => {
        setSelected(value);
    }

    useEffect(() => {
        if (selected === "10") {
            const payload = {
                "vat": selected,
            }
            callBack(payload);
        }
    }, []);

    useEffect(() => {
        const payload = {
            "vat": selected,
        }
        callBack(payload);
    }, [selected]);

    return (
        <div>
            <h4 className="text-md font-bold uppercase text-black inline">TVA</h4>

            <div className={'flex justify-around'}>
                <button
                    className={`rounded-lg p-5 border border-2 bg-gray-100 ${selected === "0" ? 'border-blue-600' : 'border-gray'}`}
                    value={0}
                    onClick={(event) => change(event.target.value)}
                >
                    0%
                </button>
                <button
                    className={`rounded-lg p-5 border border-2 bg-gray-100 ${selected === "10" ? 'border-blue-600' : 'border-gray'}`}
                    value={10}
                    onClick={(event) => change(event.target.value)}
                >
                    10%
                </button>
                <button
                    className={`rounded-lg p-5 border border-2 bg-gray-100 ${selected === "20" ? 'border-blue-600' : 'border-gray'}`}
                    value={20}
                    onClick={(event) => change(event.target.value)}
                >
                    20%
                </button>
            </div>

            <div className={'flex-column items-start m-2 text-sm text-gray-700 text-center'}>
                <button type="button" className={'flex mx-auto'} onClick={() => setShowExplanation(!showExplanation)}>
                    Comment choisir le taux de TVA ?
                    {
                        showExplanation
                            ?
                            (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                     fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                                          clipRule="evenodd"/>
                                </svg>
                            )
                            : (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                     fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                          clipRule="evenodd"/>
                                </svg>
                            )
                    }
                </button>
                <hr/>

                {
                    showExplanation
                        ? (
                            <table className={'mx-auto mb-10'}>
                                <tr>
                                    <th>0%</th>
                                    <td className={'text-left pl-5'}>Non assujetti à la TVA</td>
                                </tr>
                                <tr>
                                    <th>10%</th>
                                    <td className={'text-left pl-5'}>Logement de particulier de plus de 2 ans</td>
                                </tr>
                                <tr>
                                    <th>20%</th>
                                    <td className={'text-left pl-5'}>Logement neuf ou professionnel</td>
                                </tr>
                            </table>
                        )
                        : null
                }
            </div>
        </div>
    );
}
