import Info from "../../../../util/modal/Info";
import React from "react";
import mixin from "../../../../../mixin/mixin";
import ClientPriceInvoiceDetail from "../../quotation/ClientPriceInvoiceDetail";
import Signature from "../../signature/Signature";
import useStore from "../../../../../use/useStore";
import {useNavigate, useParams} from "react-router";
import {useSelector} from "react-redux";
import useApi from "../../../../../api/useApi";

export default function () {
    const api = useApi();
    const store = useStore();
    const {token} = useParams();
    const navigate = useNavigate();
    const intervention = useSelector(state => state.app.intervention);
    const quotation = useSelector(state => state.app.quotation);

    return (
        <div>
            <Info/>

            <div className={'my-5 p-2 flex-column mt-5'}>
                <div className="py-2">
                    {
                        intervention.client_price > 0
                            ? (
                                <div className={'text-center bg-blue-0 text-blue-1'}>
                                    <p>Reste à charge sociétaire :</p>
                                    <p className={'font-bold'}>
                                        {mixin.priceFormatter(intervention.client_price)}€ HT
                                        | {mixin.priceFormatter(intervention.client_price * ((quotation.vat / 100) + 1))}€
                                        TTC
                                    </p>
                                </div>
                            )
                            : (
                                <div className={'text-center'}>
                                    <p>Aucun reste à charge dans le cadre de l'intervention</p>
                                </div>
                            )
                    }
                </div>
                {
                    intervention.client_price > 0
                        ? (
                            <div>
                                <h4 className="font-bold">Détail du reste à charge</h4>
                                <ClientPriceInvoiceDetail/>
                            </div>
                        )
                        : null
                }
            </div>

            <Signature
                onSigned={async () => {
                    await store.init();

                    navigate(`/i/${token}`);
                }}
                onSkip={async () => {
                    await api.qualityInspection.skipCertificateSignature();
                    await store.init();
                }}
                requestedDocumentType="client_price_certificate"
            />

        </div>
    )
}