import {Fragment, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {useDispatch, useSelector} from "react-redux";
import {closeInfo} from "../../../store/util";

function Info() {
    const dispatch = useDispatch();
    const cancelButtonRef = useRef(null);
    const open = useSelector(state => state.util.modal.info.open);
    const title = useSelector(state => state.util.modal.info.title);
    const description = useSelector(state => state.util.modal.info.description);

    const close = () => {
        dispatch(closeInfo());
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className={'fixed z-10 inset-0 overflow-y-auto'} initialFocus={cancelButtonRef}
                    onClose={() => close()}>
                <div
                    className={'flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className={'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'}/>
                    </Transition.Child>

                    <span className={'hidden sm:inline-block sm:align-middle sm:h-screen'} aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className={'inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'}>
                            <div className={'bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'}>
                                <div className={'sm:flex sm:items-start'}>
                                    <div
                                        className={'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-600"
                                             fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                    </div>
                                    <div className={'mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'}>
                                        <Dialog.Title as="h3"
                                                      className={'text-lg leading-6 font-medium text-gray-900'}>
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <div className="text-sm text-gray-500">
                                                {description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    id="info-button"
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => close()}
                                >
                                    Fermer
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default Info;
