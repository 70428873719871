import E1 from "../../../../assets/img/help/signature/E1.png"
import E11 from "../../../../assets/img/help/signature/E1.1.png"
import E2 from "../../../../assets/img/help/signature/E2.png"
import E3 from "../../../../assets/img/help/signature/E3.png"
import E31 from "../../../../assets/img/help/signature/E3.1.png"

export default function () {

    return (
        <div>
            <h1 className="text-xl font-bold text-red-500 text-center">
                Accompagnez le en 3 étapes
            </h1>

            <p className="mt-5">
                <span className="font-bold text-green-600">
                Étape 1. <span className="underline">Consulter le document</span>
                </span>

                <br/>
                Le sociétaire clique sur le bouton "Voir le document"

                <img src={E1}/>
                <br/>

                Si le document ne s'affiche pas, il peut le recevoir par mail en cliquant sur le bouton "Le recevoir par
                mail"
                <img src={E11}/>
            </p>

            <p className="mt-5">
                <span className="font-bold  text-green-600">
                Étape 2. <span className="underline">Renseigner un champs de sécurité</span>
                </span>
                <br/>
                Le sociétaire sélectionne le champs de sécurité "Date de naissance" et le complète

                <img src={E2}/>
            </p>

            <p className="mt-5">
                <span className="font-bold text-green-600">
                Étape 3. <span className="underline">Signer avec le doigt</span>
                </span>
                <br/>
                Le sociétaire signe avec son doigt sur l'encadré ci après

                <img src={E3}/>

                Il ne reste plus qu'a cliquer sur le bouton "Valider"
                <img src={E31}/>
            </p>
        </div>
    );
}
