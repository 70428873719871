import React, {useState} from "react";
import useEventDispatcher from "../../../../use/useEventDispatcher";
import event from "../../../../event/event";
import {useSelector} from "react-redux";

export default function () {
    const eventDispatcher = useEventDispatcher();
    const [showedArtisanPrice, setShowedArtisanPrice] = useState(false);
    const intervention = useSelector(state => state.app.intervention);

    const onShowArtisanPriceClicked = () => {
        if (!showedArtisanPrice) {
            setShowedArtisanPrice(true);
            eventDispatcher.launcher(event.SHOW_ARTISAN_PRICE, {});

            return;
        }

        setShowedArtisanPrice(false);
        eventDispatcher.launcher(event.CLEAR_ARTISAN_PRICE, {});
    }

    return (
        <div>
            <div className={'flex justify-center items-center'}>
                <p className={'p-4 mt-10 text-gray-600 text-center w-full'}>
                    INTERVENTION TERMINEE
                </p>
            </div>

            <div className={'flex justify-center items-center'}>
                <p className={'p-4 mt-10 text-gray-600 text-center w-full'}>
                    Merci pour votre collaboration
                </p>
            </div>

            <div className={showedArtisanPrice && intervention.billing_mode === 1 ? "hidden" : null}>
                <a href="#">
                    <button
                        id="artisan-price-button"
                        className="bg-blue-1 py-3 px-5 shadow text-white w-full flex text-center"
                        onClick={onShowArtisanPriceClicked}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-5 my-auto" fill="none"
                             viewBox="0 0 24 24"
                             stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                        </svg>

                        Voir le {!showedArtisanPrice ? " montant de votre facture" : " reste à charge sociétaire"}
                    </button>
                </a>
            </div>
            <p className="my-6 text-center text-base font-bold">
                Retrouvez le montant de votre prestation à nous facturer sur votre espace dédié. (<a
                target="_blank"
                className="text-blue-500"
                href={`https://${process.env.REACT_APP_ARTISAN_APP_URL}`}>{process.env.REACT_APP_ARTISAN_APP_URL}</a>)
            </p>
        </div>
    );
}
