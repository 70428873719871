import React, {useRef, useState} from "react";
import NextStepButton from "./NextStepButton";
import mixin from "../../../mixin/mixin";
import useStore from "../../../use/useStore";
import useAlert from "../../../use/useAlert";
import enums from "../../../enum/enums";
import useApi from "../../../api/useApi";

function StepPictureAfter() {
    const api = useApi();
    const store = useStore();
    const alert = useAlert();
    const cameraInput = useRef();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [pictureAfter, setPictureAfter] = useState([]);

    const MAX_AMOUNT_PICTURE = 3;

    const openCamera = () => {
        if (pictureAfter.length >= MAX_AMOUNT_PICTURE) {
            alert.launch("Maximum " + MAX_AMOUNT_PICTURE + " photos", "warning");
            return;
        }

        cameraInput.current.click();
    };

    const imageUploaded = async (file) => {
        if (file === null || file === undefined) {
            alert.launch("Aucune image", "warning");
            return;
        }

        if (file.type.startsWith("image")) {
            const data = await mixin.compressFile(file, {
                size: 1,
                quality: .5,
                rotate: false
            });

            let contentType = file.type;
            let content = data[0].data;

            setPictureAfter((prevState) => ([...prevState, {"content": content, "content-type": contentType}]));

        } else {
            const reader = new FileReader();

            reader.onload = ((f) => (e) => {
                let contentType = file.type;
                let content = e.target.result.replace("data:" + contentType + ";base64,", "");

                setPictureAfter((prevState) => ([...prevState, {"content": content, "content-type": contentType}]));
            })(file);

            reader.readAsDataURL(file);
        }
    }

    const deletePicture = (index) => {
        const imagesArray = pictureAfter;
        imagesArray.splice(index, 1);

        setPictureAfter([...imagesArray]);

    }

    const nextStep = async () => {
        if (pictureAfter.length === 0) {
            alert.launch("Merci de prendre la photo après intervention", "warning");
            return;
        }

        setButtonLoading(true);
        const isSuccess = await api.qualityInspection.nextStep({
            uploaded_file: {
                quality_inspection_picture_after_files: pictureAfter
            },
            quality_inspection: {
                current_step: enums.qualityInspection.STEP_PICTURE_AFTER
            }
        });

        if (isSuccess) {
            await store.init();
            return;
        }

        setButtonLoading(false);
        alert.launch("Une erreur s'est produite", "error");
    }

    return (
        <div>
            <div>
                <div className={'my-2 p-2 flex bg-blue-0 text-blue-1 rounded items-center mt-10'}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={'h-6 w-6 mx-3'} fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                    <div className="leading-tight">
                        Cette étape est essentielle pour le traitement accéléré de votre facture
                    </div>
                </div>
                <div className={'my-5 flex-column'}>
                    <div className={'flex flex-col my-2'}>
                        <button
                            id="picture-after-button"
                            className="bg-blue-600 text-white p-3 flex justify-center w-full"
                            onClick={() => openCamera()}
                        >
                            <input
                                ref={cameraInput}
                                className="custom-file-input cursor-pointer"
                                type="file"
                                accept="image/*"
                                style={{display: "none"}}
                                name="pictureAfter"
                                multiple
                                onChange={(event) => {
                                    imageUploaded(event.target.files[0]);
                                    event.target.value = null
                                }}
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" className={'h-6 w-6 mx-1'} fill="none"
                                 viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"/>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"/>
                            </svg>
                            <label>
                                Prendre une photo ({MAX_AMOUNT_PICTURE} maximum)
                            </label>
                        </button>
                    </div>

                    {
                        pictureAfter.map((picture, index) => {
                            if (picture === null || picture["content-type"] === null || picture.content === null) {
                                return null;
                            }

                            return (
                                <div key={index} className="picture">
                                    <img

                                        src={`data:${picture['content-type']};base64,${picture.content}`}
                                    />
                                    <button
                                        className="close text-red-1"
                                        onClick={() => deletePicture(index)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M6 18L18 6M6 6l12 12"/>
                                        </svg>
                                    </button>
                                </div>
                            )
                        })
                    }

                </div>

                <NextStepButton
                    title={'Je rédige mon Procès-Verbal'}
                    callback={nextStep} loading={buttonLoading}
                    style="bg-blue-900"
                />
            </div>
        </div>
    )
}

export default StepPictureAfter;
