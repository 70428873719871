import axios from "axios";
import {useParams} from "react-router";

const prefix = 'service-emergency';

export default function () {
    const {token} = useParams();

    return {
        async updatePhone1(phone1) {
            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/clients/by/token/${token}/phone_1`, {
                    client: {
                        phone_1: phone1
                    }
                });
                return response.status === 204;
            } catch (e) {
                return false;
            }
        },
        async updateEmail(email) {
            if (token === "demo") {
                return true;
            }

            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/clients/by/token/${token}/email`, {
                    client: {
                        email: email
                    }
                });
                return response.status === 204;
            } catch (e) {
                return false;
            }
        }
    };
}
