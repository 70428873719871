import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import enums from "../../../enum/enums";
import {updateInterventionCompanyComment} from "../../../store/app";
import useApi from "../../../api/useApi";
import useAlert from "../../../use/useAlert";

export default function ({onSuccess}) {
    const api = useApi();
    const dispatch = useDispatch();
    const alert = useAlert();
    const companyComment = useRef();
    const currentStep = useSelector(state => state.app.qualityInspection.current_step);
    const intervention = useSelector(state => state.app.intervention);

    const onSubmit = async data => {
        const comment = companyComment.current.value;

        data.preventDefault();

        if (comment == "" || comment == null) {
            alert.launch("Veuillez remplir le champ afin de pouvoir envoyer votre commentaire", "warning");
            return;
        }

        const step = "[ Le " + (new Date().toLocaleString()) + " à l'étape " + stepResolver() + " ] ";

        const companyComments = intervention.company_comment === ""
        || intervention.company_comment === null
        || intervention.company_comment === undefined
            ? step + comment
            : intervention.company_comment + "\n" + step + comment;

        const isSuccess = await api.intervention.updateCompanyComment(companyComments)

        if (!isSuccess) {
            alert.launch("Une erreur s'est produite", "error");
            return;
        }

        alert.launch("Votre commentaire a été envoyé avec succès", "success");

        dispatch(updateInterventionCompanyComment(companyComments));

        onSuccess();
    }

    function stepResolver() {
        switch (currentStep) {
            case enums.qualityInspection.STEP_INITIALIZED:
                return "RDV";
            case enums.qualityInspection.STEP_APPOINTMENT:
                return "DEP";
            case enums.qualityInspection.STEP_TRAVEL:
                return "Photo AV";
            case enums.qualityInspection.STEP_PICTURE_BEFORE:
                return "Devis";
            case enums.qualityInspection.STEP_QUOTATION:
                return "Val Devis";
            case enums.qualityInspection.STEP_CLIENT_SIGNATURE:
                return "Inter";
            case enums.qualityInspection.STEP_WORK:
                return "Photo AP";
            case enums.qualityInspection.STEP_PICTURE_AFTER:
                return "PV";
            case enums.qualityInspection.STEP_DELIVERY:
                return "Fin inter";
        }
    }


    return (
        <div>
            <h6 className="font-bold text-2xl mb-2">
                Je transmets une information :
            </h6>

            <form onSubmit={onSubmit}>

                <textarea
                    ref={companyComment}
                    placeholder="Votre message"
                    className="text-center w-full bg-gray-100 focus:border-blue-500 rounded p-2 mb-2 py-7"
                />

                <input
                    className="bg-blue-600 text-white py-2 my-2 flex justify-center w-full"
                    type="submit"
                    value="Envoyer le message"
                />

            </form>
        </div>
    );
}
