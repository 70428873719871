import React, {useEffect, useRef, useState} from "react";
import isNull from "../../../mixin/isNull";

export default function ({nextStep}) {
    const clientPriceInfoInterval = useRef();
    const clientPriceDetailsInterval = useRef();
    const artisanPriceButtonInterval = useRef();
    const artisanPriceInfoInterval = useRef();
    const [stepCompleted, setStepCompleted] = useState(false);

    useEffect(() => {
        play();
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(clientPriceInfoInterval.current);
            clearInterval(clientPriceDetailsInterval.current);
            clearInterval(artisanPriceButtonInterval.current);
            clearInterval(artisanPriceInfoInterval.current);
        }
    }, []);

    const play = () => {
        setTimeout(() => {
            window.sound.src = "/demo/step-11.1.mp3";
            window.sound.play();
        }, 500);

        setTimeout(() => {
            window.sound.src = "/demo/step-11.2.mp3";
            window.sound.play();
        }, 7500);

        setTimeout(() => {
            window.sound.src = "/demo/step-11.3.mp3";
            window.sound.play();
        }, 14500);

        setTimeout(() => {
            window.sound.src = "/demo/step-11.4.mp3";
            window.sound.play();
        }, 23500);

        setTimeout(() => {
            window.sound.src = "/demo/step-11.5.mp3";
            window.sound.play();
        }, 33500);

        launchClientPriceInfo();
        launchClientPriceDetails();
        launchRequestedDocument();
        launchArtisanPriceButton();
        launchArtisanPriceInfo();
    }


    const launchClientPriceInfo = () => {
        window.scrollTo(0, 0);

        setTimeout(() => {
            clientPriceInfoInterval.current = setInterval(() => {
                const clientPriceInfo = document.getElementById("client-price-info");


                if (clientPriceInfo.style.background === "" || clientPriceInfo.style.background === "white") {
                    clientPriceInfo.style.background = "#eb4435";
                    clientPriceInfo.style.color = "white";
                } else {
                    clientPriceInfo.style.background = "white";
                    clientPriceInfo.style.color = "rgb(0, 127, 254)";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(clientPriceInfoInterval.current);

                const clientPriceInfo = document.getElementById("client-price-info");

                clientPriceInfo.style.background = "white";
                clientPriceInfo.style.color = "rgb(0, 127, 254)";
            }, 5000);
        }, 1000);
    }


    const launchClientPriceDetails = () => {
        setTimeout(() => {
            const requestedDocument1 = document.getElementById("requested-document-1");

            requestedDocument1.focus();

            clientPriceDetailsInterval.current = setInterval(() => {
                const clientPriceDetails = document.getElementById("client-price-details");


                if (clientPriceDetails.style.background === "" || clientPriceDetails.style.background === "white") {
                    clientPriceDetails.style.background = "#eb4435";
                    clientPriceDetails.style.color = "white";
                } else {
                    clientPriceDetails.style.background = "white";
                    clientPriceDetails.style.color = "black";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(clientPriceDetailsInterval.current);

                const clientPriceDetails = document.getElementById("client-price-details");

                clientPriceDetails.style.background = "white";
                clientPriceDetails.style.color = "black";
            }, 5000);
        }, 10500);
    }

    const launchRequestedDocument = () => {
        setTimeout(async () => {
            let id = 1;

            let element = document.getElementById("requested-document-" + id);

            do {
                await new Promise(resolve => setTimeout(async () => {
                    element.focus();
                    element.style.background = "red";

                    await new Promise(resolve => setTimeout(() => {
                        element.style.background = "rgb(0, 127, 254)";
                        resolve();
                    }, 1000))

                    resolve();
                }, 500));

                id++;
                element = document.getElementById("requested-document-" + id);
            } while (!isNull(element));
        }, 15500);
    }

    const launchArtisanPriceButton = () => {
        setTimeout(() => {
            artisanPriceButtonInterval.current = setInterval(() => {
                const artisanPriceButton = document.getElementById("artisan-price-button");

                artisanPriceButton.focus();

                if (artisanPriceButton.style.background === "" || artisanPriceButton.style.background === "rgb(0, 127, 254)") {
                    artisanPriceButton.style.background = "#eb4435";
                } else {
                    artisanPriceButton.style.background = "rgb(0, 127, 254)";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(artisanPriceButtonInterval.current);

                const artisanPriceButton = document.getElementById("artisan-price-button");

                artisanPriceButton.style.background = "rgb(0, 127, 254)";

                setTimeout(() => {
                    artisanPriceButton.style.background = "yellow";

                    setTimeout(() => {
                        artisanPriceButton.click();
                        artisanPriceButton.style.background = "rgb(0, 127, 254)";

                        window.scrollTo(0, 0);
                    }, 300);
                }, 1000);
            }, 5000);
        }, 25500);
    }

    const launchArtisanPriceInfo = () => {

        setTimeout(() => {
            artisanPriceInfoInterval.current = setInterval(() => {
                const artisanPriceInfo = document.getElementById("artisan-price-info");


                if (artisanPriceInfo.style.background === "" || artisanPriceInfo.style.background === "white") {
                    artisanPriceInfo.style.background = "#eb4435";
                    artisanPriceInfo.style.color = "white";
                } else {
                    artisanPriceInfo.style.background = "white";
                    artisanPriceInfo.style.color = "rgb(0, 127, 254)";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(artisanPriceInfoInterval.current);

                const artisanPriceInfo = document.getElementById("artisan-price-info");

                artisanPriceInfo.style.background = "white";
                artisanPriceInfo.style.color = "rgb(0, 127, 254)";

                setTimeout(() => {
                    setStepCompleted(true);
                }, 1000);
            }, 5000);
        }, 31500);
    }

    return (
        <div
            className={`${stepCompleted ? "bg-white/80" : "bg-white/30"} fixed z-50 h-full w-full max-w-2xl flex justify-center`}>
            {
                stepCompleted
                    ? (
                        <button className="bg-blue-1 h-16 mt-56 px-5 rounded-lg text-white" onClick={play}>
                            Votre formation est terminée.
                            <br/>
                            Vous pouvez fermer la page.
                        </button>
                    )
                    : null
            }
        </div>
    );
}