import {useSelector} from "react-redux";
import mixin from "../../../../mixin/mixin";

export default function CheckPayment({abort, paymentSucceeded}) {
    const intervention = useSelector(state => state.app.intervention);
    const quotation = useSelector(state => state.app.quotation);

    return (
        <div>
            <div className="text-center mt-10 px-2">
                Veuillez récupérer le chèque auprès du sociétaire pour un montant
                de {mixin.priceFormatter(intervention.client_price * (quotation.vat / 100 + 1))} € à l'ordre de FAIRFAIR
                GROUP
            </div>
            <div className="text-center mt-8 px-2">
                Merci de l'envoyer à l'adresse: <b>132, Rue Marquis de Laplace, 34500 Béziers</b>
            </div>
            <div className="text-white w-full max-w-2xl mx-auto grid grid-cols-2 gap-4 mt-15 fixed-bottom">
                <div className="col-span-1">
                    <button
                        className="bg-red-1 w-full p-2 h-16"
                        onClick={abort}
                    >
                        Annuler
                    </button>
                </div>
                <div className="col-span-1">
                    <button
                        className="bg-blue-1 w-full p-2 h-16"
                        onClick={paymentSucceeded}
                    >
                        Chèque récupéré
                    </button>
                </div>
            </div>
        </div>
    )
}
