import axios from "axios";
import {useParams} from "react-router";
import enums from "../enum/enums";

const prefix = 'service-emergency';

export default function () {
    const {token} = useParams();

    return {
        async isSigned(type) {
            if (token === "demo") return false;

            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/requested_documents/${type}/signed/${token}`);
                return response.data.is_signed;
            } catch (e) {
                return null;
            }
        },
        async getAllRequestedDocumentByToken() {
            if (token === "demo") {
                return [
                    {
                        id: 1,
                        name: "Facture de reste à charge",
                        status: enums.requestedDocument.STATUS_WAITING_FOR_PROVIDE
                    },
                    {
                        id: 2,
                        name: "Procès verbal de fin d'intervention",
                        status: enums.requestedDocument.STATUS_WAITING_FOR_PROVIDE
                    }
                ]
            }

            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/requested_documents/by/token/${token}`);
                return response.data;
            } catch (e) {
                return null;
            }
        },
        async patchDocument(id, content, contentType) {
            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/requested_documents/${id}/document_id/by/token/${token}`, {
                    uploaded_file: {
                        requested_document_document: {
                            content: content,
                            'content-type': contentType
                        }
                    }
                });

                return response.status === 204;
            } catch (e) {
                return null;
            }
        }
    };
}

