import {useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import isNull from "../../../mixin/isNull";
import enums from "../../../enum/enums";

export default function ({nextStep}) {
    const demo = useSelector(state => state.demo);
    const pictureAfterButtonInterval = useRef();
    const preferredInterlocutorButtonInterval = useRef();
    const [stepCompleted, setStepCompleted] = useState(false);

    useEffect(() => {
        play();
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(pictureAfterButtonInterval.current);
            clearInterval(preferredInterlocutorButtonInterval.current);
        }
    }, []);

    useEffect(() => {
        if (isNull(demo)) return;

        if (demo.current_step === enums.qualityInspection.STEP_PICTURE_AFTER) {
            nextStep();
        }
    }, [demo]);

    const play = () => {
        setTimeout(() => {
            window.sound.src = "/demo/step-8.1.mp3";
            window.sound.play();
        }, 500);

        setTimeout(() => {
            window.sound.src = "/demo/step-8.2.mp3";
            window.sound.play();
        }, 7500);

        setTimeout(() => {
            window.sound.src = "/demo/step-8.3.mp3";
            window.sound.play();
        }, 14500);

        launchPictureAfterButton();
        launchPreferredInterlocutorButton();
    }


    const launchPictureAfterButton = () => {
        setTimeout(() => {
            pictureAfterButtonInterval.current = setInterval(() => {
                const pictureAfterButton = document.getElementById("picture-after-button");

                if (pictureAfterButton.style.background === "" || pictureAfterButton.style.background === "rgb(0, 127, 254)") {
                    pictureAfterButton.style.background = "red";
                    pictureAfterButton.style.color = "white";
                } else {
                    pictureAfterButton.style.background = "rgb(0, 127, 254)";
                    pictureAfterButton.style.color = "white";
                }

            }, 500);
        }, 1000);

        setTimeout(() => {
            clearInterval(pictureAfterButtonInterval.current);

            const pictureAfterButton = document.getElementById("picture-after-button");

            pictureAfterButton.style.background = "rgb(0, 127, 254)";
            pictureAfterButton.style.color = "white";
        }, 5000);
    }

    const launchPreferredInterlocutorButton = () => {
        setTimeout(() => {
            preferredInterlocutorButtonInterval.current = setInterval(() => {
                const preferredInterlocutorButton = document.getElementById("preferred-interlocutor-button");

                if (preferredInterlocutorButton.style.background === "" || preferredInterlocutorButton.style.background === "rgb(249, 115, 22)") {
                    preferredInterlocutorButton.style.background = "red";
                } else {
                    preferredInterlocutorButton.style.background = "rgb(249, 115, 22)";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(preferredInterlocutorButtonInterval.current);

                const preferredInterlocutorButton = document.getElementById("preferred-interlocutor-button");

                preferredInterlocutorButton.style.background = "rgb(249, 115, 22)";
                setStepCompleted(true);
            }, 5000);
        }, 9000);
    }

    if (stepCompleted) return null;

    return (
        <div className="bg-white/30 fixed z-50 h-full w-full max-w-2xl">

        </div>
    );
}