import React from 'react';

export default function () {
    return (
        <div className={'flex justify-center'}>
            <div className={'w-80 mt-10 shadow-lg'}>
                <p className={'text-center'}>
                    404 NOT FOUND
                </p>
            </div>
        </div>
    );
}

