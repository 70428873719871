import axios from "axios";

const prefix = 'service-user';

export default function () {
    return {
        async getWork(id) {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/guest/works/${id}`);
                return response.data;
            } catch (e) {
                return null;
            }
        }
    };
}
