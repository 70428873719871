import axios from "axios";
import {useParams} from "react-router";
import {demo} from "../store/demo";

const prefix = 'service-emergency';

export default function () {
    const {token} = useParams();

    return {
        async getSupportedPrice() {
            if (token === "demo") {
                return [
                    {
                        name: "Deplacement",
                        description: null,
                        active: true,
                        quantity: 1,
                        price: 60,
                        default_item_id: 11,
                        can_be_increased: true,
                        standardized_item_id: null
                    },
                    {
                        name: "Main d'oeuvre",
                        description: null,
                        active: true,
                        quantity: 1,
                        price: 70,
                        default_item_id: 12,
                        can_be_increased: true,
                        standardized_item_id: null
                    }
                ];
            }

            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/interventions/supported_price/by/token/${token}`);
                return response.data;
            } catch (e) {
                return null;
            }
        }
    }

};