import useApi from "../api/useApi";

export default function () {
    const api = useApi();
    let lastUpdateAt = null;

    return artisanId => {
        const success = position => {
            if (lastUpdateAt === null || new Date().setMinutes(new Date().getMinutes() - 2) > lastUpdateAt) {
                api.artisan.updateCurrentPosition(artisanId, position.coords.latitude, position.coords.longitude);

                lastUpdateAt = new Date();
            }
        };

        const error = positionError => {
        }

        navigator.geolocation.watchPosition(success, error);
    };
}
