import React, {useEffect, useState} from "react";
import isNull from "../../../mixin/isNull";
import mixin from "../../../mixin/mixin";
import CreateQuotationItem from "./CreateQuotationItem";
import UpdateQuotationItem from "./UpdateQuotationItem";
import StandardizedItemSelect from "../intervention/quotation/StandardizedItemSelect";
import useEventDispatcher from "../../../use/useEventDispatcher";
import event from "../../../event/event";
import PlusIcon from "../../util/icon/PlusIcon";
import {useSelector} from "react-redux";
import useApi from "../../../api/useApi";

export default function ({onTerminate}) {
    const api = useApi();
    const eventDispatcher = useEventDispatcher();
    const [quotationItems, setQuotationItems] = useState([]);
    const [showFormCreate, setShowFormCreate] = useState(false);
    const [updateQuotationItem, setUpdateQuotationItem] = useState(null);
    const order = useSelector(state => state.app.order);
    const shiftingItemName = "Déplacement supplémentaire";
    const [shiftingItemPrice, setShiftingPrice] = useState(order.sell_price_shifting);
    const mainWorkItemName = "Main d'oeuvre supplémentaire";
    const [mainWorkItemPrice, setMainWorkItemPrice] = useState(order.sell_price_main_work);


    useEffect(() => {
        fetchItems();

        const recipient = () => {
            setShowFormCreate(true);
        }

        eventDispatcher.subscribe(event.SHOW_CREATE_QUOTATION_ITEM, recipient);

        return () => eventDispatcher.unsubscribe(event.SHOW_CREATE_QUOTATION_ITEM, recipient);
    }, []);

    const fetchItems = async () => {
        setQuotationItems(await api.quotationItems.getQuotationItem());
    }

    const remove = async id => {
        await api.quotationItems.deleteQuotationItem(id);
        await fetchItems();
    }

    const createQuotationItem = async item => {
        item.standardized_item_id = item.standardizedItemId;

        await api.quotationItems.createQuotationItem({
            quotation_item: item
        })
        fetchItems();
    }

    if (showFormCreate) {
        return <CreateQuotationItem
            backCallback={() => setShowFormCreate(false)}
            onSuccess={() => {
                setShowFormCreate(false);
                fetchItems();
            }}
        />
    }

    const addShifting = () => {
        const shifting = {
            name: shiftingItemName,
            description: null,
            price: shiftingItemPrice,
            quantity: 1,
            default_item_id: null,
            standardized_item_id: null,
            is_managed: true
        }

        createQuotationItem(shifting);
    }

    const addMainWork = () => {
        if (quotationItems.find(quotationItem => quotationItem.name === mainWorkItemName) !== undefined) {
            return;
        }

        const mainWork = {
            name: mainWorkItemName,
            description: null,
            price: mainWorkItemPrice,
            quantity: 1,
            default_item_id: null,
            standardized_item_id: null,
            is_managed: true
        }

        createQuotationItem(mainWork);
    }

    if (!isNull(updateQuotationItem)) {
        return <UpdateQuotationItem
            backCallback={() => setUpdateQuotationItem(null)}
            onSuccess={() => {
                setUpdateQuotationItem(null);
                fetchItems();
            }}
            quotationItem={updateQuotationItem}
        />
    }

    return (
        <div className="px-1 mt-5">
            {
                quotationItems.map(quotationItem => {
                    return isNull(quotationItem.default_item_id) ?
                        (
                            <div className="flex items-center mt-2" key={quotationItem.id}>
                                <div className="flex-1 bg-gray-100 shadow p-2"
                                     onClick={() => setUpdateQuotationItem(quotationItem)}>
                                    <div className="grid grid-cols-4">
                                        <div className="col-span-2 text-base">{quotationItem.name}</div>
                                        <div className="flex justify-center text-base">
                                                <span
                                                    className="font-bold">{mixin.priceFormatter(quotationItem.price)}</span>€
                                        </div>
                                        <div className="text-right text-base">x {quotationItem.quantity}</div>
                                    </div>
                                </div>
                                <div className="ml-2">
                                    <button
                                        className={`bg-red-500 p-2 text-white`}
                                        onClick={() => remove(quotationItem.id)}
                                    >
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor"
                                             viewBox="0 0 24 24"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        )
                        : null
                })
            }

            <div className="w-full mt-10">
                <StandardizedItemSelect quotationItems={quotationItems} onItemSelected={createQuotationItem}/>
            </div>

            <div className={'flex flex-col my-2 mt-5 mb-7'}>
                <button
                    className={`bg-blue-600 text-white py-2 my-2 flex justify-center w-full ${quotationItems.find(quotationItem => quotationItem.name === shiftingItemName) !== undefined ? "hidden" : null}`}
                    onClick={addShifting}
                >
                    Déplacement supplémentaire
                    <div className="my-auto ml-2">
                        <PlusIcon size={6}/>
                    </div>
                </button>
                <button
                    className={`bg-blue-600 text-white py-2 my-2 flex justify-center w-full ${quotationItems.find(quotationItem => quotationItem.name === mainWorkItemName) !== undefined ? "hidden" : null}`}
                    onClick={addMainWork}
                >
                    Main d'oeuvre supplémentaire
                    <div className="my-auto ml-2">
                        <PlusIcon size={6}/>
                    </div>
                </button>
            </div>

            <div>
                <button className="bg-blue-1 py-4 w-full text-white" onClick={async () => {
                    onTerminate();
                }}>
                    Modification terminé
                </button>
            </div>
        </div>
    );
}
