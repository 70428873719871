import axios from "axios";
import {useParams} from "react-router";

const prefix = "service-emergency";

export default function () {
    const {token} = useParams();

    return {
        async getQuotationItem() {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/quotation_items/by/token/${token}`);
                return response.data;
            } catch (e) {
                return null;
            }
        },
        async createQuotationItem(payload) {
            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + `/${prefix}/application/quotation_items/by/token/${token}`, payload);

                return response.data;
            } catch (e) {
                return null;
            }
        },
        async updateQuotationItemName(id, name) {
            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/quotation_items/by/token/${token}/${id}/name`, {
                    quotation_item: {
                        name: name
                    }
                });
                return response.status === 204;
            } catch (e) {
                return null;
            }
        },
        async updateQuotationItemDescription(id, description) {
            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/quotation_items/by/token/${token}/${id}/description`, {
                    quotation_item: {
                        description: description
                    }
                });
                return response.status === 204;
            } catch (e) {
                return null;
            }
        },
        async updateQuotationItemPrice(id, price) {
            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/quotation_items/by/token/${token}/${id}/price`, {
                    quotation_item: {
                        price: price
                    }
                });
                return response.status === 204;
            } catch (e) {
                return null;
            }
        },
        async updateQuotationItemQuantity(id, quantity) {
            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/quotation_items/by/token/${token}/${id}/quantity`, {
                    quotation_item: {
                        quantity: quantity
                    }
                });
                return response.status === 204;
            } catch (e) {
                return null;
            }
        },
        async deleteQuotationItem(id) {
            try {
                const response = await axios.delete(process.env.REACT_APP_API_URL + `/${prefix}/application/quotation_items/by/token/${token}/${id}`);
                return response.status === 204;
            } catch (e) {
                return null;
            }
        }
    }
}

