import React from "react";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {openAlert} from "../../../store/util";
import isNull from "../../../mixin/isNull";
import useApi from "../../../api/useApi";

/**
 * @param backCallback function ()
 * @param createCallback function (name, description, price, quantity, standardizedItemId = null)
 * @returns {JSX.Element}
 * @constructor
 */
export default function CreateQuotationItem({backCallback, onSuccess}) {
    const api = useApi();
    const dispatch = useDispatch();
    const {register, handleSubmit} = useForm();

    const submit = async data => {
        const quotationItem = await api.quotationItems.createQuotationItem({
            quotation_item: data
        });

        if (!isNull(quotationItem)) {
            onSuccess();
        } else {
            dispatch(openAlert({
                type: 'warning',
                title: "Une erreur s'est produite"
            }));
        }
    }

    return (
        <div className="mt-5 px-1">
            <form onSubmit={handleSubmit(submit)}>
                <div className="w-full">
                    <label htmlFor="name">Nom de la pièce</label>
                    <input
                        className="text-center w-full bg-gray-100 focus:border-blue-500 rounded p-2 mb-2"
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Nom de la pièce"
                        {...register('name', {required: true})}
                    />
                </div>

                <div className="w-full">
                    <label htmlFor="description">Description de la pièce</label>
                    <input
                        className="text-center w-full bg-gray-100 focus:border-blue-500 rounded p-2 mb-2"
                        id="description"
                        type="text"
                        name="description"
                        placeholder="Marque, Référence, Taille ..."
                        {...register('description')}
                    />
                </div>

                <div className="w-full">
                    <label htmlFor="price">Prix HT</label>
                    <input
                        id="price"
                        type="number"
                        step="0.01"
                        className="text-center w-full bg-gray-100 focus:border-blue-500 rounded p-2 mb-2"
                        placeholder="Prix HT"
                        name="price"
                        {...register('price', {required: true})}
                    />
                </div>

                <div className="w-full">
                    <label htmlFor="quantity">Quantité</label>
                    <input
                        id="quantity"
                        type="number"
                        step="0.5"
                        className="text-center w-full bg-gray-100 focus:border-blue-500 rounded p-2 mb-2"
                        placeholder="Quantité"
                        name="quantity"
                        defaultValue={1}
                        {...register('quantity', {required: true})}
                    />
                </div>

                <div className={'flex space-x-2'}>
                    <button
                        type="button"
                        className={'bg-red-600 flex flex-1 p-2 text-white font-semibold justify-center'}
                        onClick={backCallback}>
                        <svg className="w-6 h-6 my-auto" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"/>
                        </svg>
                        Retour
                    </button>
                    <button
                        type="submit"
                        className="bg-blue-600 p-2 flex flex-1 text-white font-semibold justify-center">
                        <svg className="w-6 h-6 my-auto" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"/>
                        </svg>
                        Ajouter
                    </button>
                </div>
            </form>
        </div>
    );
}
