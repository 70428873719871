import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {store} from './store/store'
import {Provider} from 'react-redux'
import 'tw-elements';
import Router from "./router/Router";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

