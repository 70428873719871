import React, {useState} from "react";
import Billing from "./finally/Billing";
import Document from "./finally/Document";
import ApplicationFeedback from "./finally/ApplicationFeedback";
import Signature from "./signature/Signature";

export default function Finally() {
    const [isDeliveryCertificateSigned, setDeliveryCertificateSigned] = useState(false);

    if (!isDeliveryCertificateSigned) {
        return (
            <div>
                <h1 className="text-center text-xl font-bold py-5">Procès verbal</h1>
                <Signature
                    onSkip={() => setDeliveryCertificateSigned(true)}
                    onSigned={() => setDeliveryCertificateSigned(true)}
                    requestedDocumentType="delivery_certificate"
                />
            </div>
        );
    }

    return (
        <div>
            <div className={'my-5 p-2 flex-column mt-10'}>
                <Billing/>
                <hr/>
                <Document/>
                <hr/>
                <ApplicationFeedback/>
            </div>
        </div>
    );
}

