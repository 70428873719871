import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import isNull from "../../../mixin/isNull";
import enums from "../../../enum/enums";
import {update} from "../../../store/demo";

export default function ({nextStep}) {
    const dispatch = useDispatch();
    const demo = useSelector(state => state.demo);
    const quotationValidationAssistancePhoneButtonInterval = useRef();
    const [stepCompleted, setStepCompleted] = useState(false);

    useEffect(() => {
        play();
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(quotationValidationAssistancePhoneButtonInterval.current);
        }
    }, []);

    useEffect(() => {
        if (isNull(demo)) return;

        if (demo.current_sub_step === enums.qualityInspection.SUB_STEP_ALL_VALIDATED) {
            nextStep();
        }
    }, [demo]);

    const play = () => {
        setTimeout(() => {
            window.sound.src = "/demo/step-5.1.mp3";
            window.sound.play();
        }, 500);

        launchQuotationValidationPhoneButton();
    }


    const launchQuotationValidationPhoneButton = () => {
        setTimeout(() => {
            quotationValidationAssistancePhoneButtonInterval.current = setInterval(() => {
                const quotationValidationAssistancePhoneButton = document.getElementById("quotation-validation-assistance-phone-button");

                if (quotationValidationAssistancePhoneButton.style.background === "" || quotationValidationAssistancePhoneButton.style.background === "rgb(0, 127, 254)") {
                    quotationValidationAssistancePhoneButton.style.background = "#eb4435";
                    quotationValidationAssistancePhoneButton.style.color = "white";
                } else {
                    quotationValidationAssistancePhoneButton.style.background = "rgb(0, 127, 254)";
                    quotationValidationAssistancePhoneButton.style.color = "white";
                }

            }, 500);
        }, 1000);

        setTimeout(() => {
            clearInterval(quotationValidationAssistancePhoneButtonInterval.current);

            const quotationValidationAssistancePhoneButton = document.getElementById("quotation-validation-assistance-phone-button");

            quotationValidationAssistancePhoneButton.style.background = "#eb4435";
            quotationValidationAssistancePhoneButton.style.color = "white";
            validQuotation();
        }, 5000);
    }

    const validQuotation = () => {
        setTimeout(() => {
            dispatch(update({
                property: "quotation_validate",
                value: true
            }));

            dispatch(update({
                property: "current_sub_step",
                value: enums.qualityInspection.SUB_STEP_ALL_VALIDATED
            }));
        }, 5000);
    }

    if (stepCompleted) return null;

    return (
        <div className="bg-white/30 fixed z-50 h-full w-full max-w-2xl">

        </div>
    );
}