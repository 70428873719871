import axios from "axios";
import {useParams} from "react-router";

const prefix = "service-emergency";

export default function () {
    const {token} = useParams();

    return {
        async getQuotation() {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/quotations/by/token/${token}`);
                return response.data;
            } catch (e) {
                return null;
            }
        },
        async updateQuotationVat(vat) {
            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `/${prefix}/application/quotations/by/token/${token}/vat`, {
                    quotation: {
                        vat: vat
                    }
                });
                return response.status === 204;
            } catch (e) {
                return null;
            }
        }
    }
}

