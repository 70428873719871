import {useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import isNull from "../../../mixin/isNull";
import enums from "../../../enum/enums";

export default function ({nextStep}) {
    const demo = useSelector(state => state.demo);
    const pictureBeforeButtonInterval = useRef();
    const [stepCompleted, setStepCompleted] = useState(false);

    useEffect(() => {
        play();
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(pictureBeforeButtonInterval.current);
        }
    }, []);

    useEffect(() => {
        if (isNull(demo)) return;

        if (demo.current_step === enums.qualityInspection.STEP_PICTURE_BEFORE) {
            nextStep();
        }
    }, [demo]);

    const play = () => {
        setTimeout(() => {
            window.sound.src = "/demo/step-3.1.mp3";
            window.sound.play();
        }, 500);

        setTimeout(() => {
            window.sound.src = "/demo/step-3.2.mp3";
            window.sound.play();
        }, 7500);

        launchPictureBeforeButton();
    }


    const launchPictureBeforeButton = () => {
        setTimeout(() => {
            setTimeout(() => {
                pictureBeforeButtonInterval.current = setInterval(() => {
                    const pictureBeforeButton = document.getElementById("picture-before-button");

                    if (pictureBeforeButton.style.background === "" || pictureBeforeButton.style.background === "rgb(0, 127, 254)") {
                        pictureBeforeButton.style.background = "red";
                        pictureBeforeButton.style.color = "white";
                    } else {
                        pictureBeforeButton.style.background = "rgb(0, 127, 254)";
                        pictureBeforeButton.style.color = "white";
                    }

                }, 500);
            }, 1000);

            setTimeout(() => {
                clearInterval(pictureBeforeButtonInterval.current);

                const pictureBeforeButton = document.getElementById("picture-before-button");

                pictureBeforeButton.style.background = "rgb(0, 127, 254)";
                pictureBeforeButton.style.color = "white";
                setStepCompleted(true);
            }, 4000);
        }, 3000);
    }

    if (stepCompleted) return null;

    return (
        <div className="bg-white/30 fixed z-50 h-full w-full max-w-2xl">

        </div>
    );
}