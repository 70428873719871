import NextStepButton from "../../NextStepButton";
import React, {useEffect, useRef} from "react";
import qualityInspection from "../../../../../api/useQualityInspection";
import {useParams} from "react-router";
import useStore from "../../../../../use/useStore";

export default function () {
    const {token} = useParams();
    const store = useStore();
    const interval = useRef();

    useEffect(() => {
        interval.current = setInterval(async () => {
            const isPayed = await qualityInspection.isPayed(token);

            if (isPayed) {
                store.init();
            }
        }, 3000);

        return () => clearInterval(interval.current);
    }, []);

    return (
        <div>
            <div
                className="text-white flex bg-red-500 justify-center rounded-lg border shadow-lg mt-20 w-full max-w-2xl p-3 text-sm w-full">
                Veuillez patienter, nous prenons l'empreinte bancaire du sociétaire ...
            </div>

            <div
                className="text-white text-center flex bg-orange-500 justify-center rounded-lg border shadow-lg mt-20 w-full max-w-2xl p-3 text-sm w-full">
                Le sociétaire va recevoir un SMS, attention, il s'agit uniquement d'une empreinte bancaire. <br/>Le
                montant sera prélévé si l'intervention s'est correctement déroulé
            </div>


            <NextStepButton loading={true} style="fixed"/>
        </div>
    );
}