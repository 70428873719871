import {useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import isNull from "../../../mixin/isNull";
import enums from "../../../enum/enums";

export default function ({nextStep}) {
    const demo = useSelector(state => state.demo);
    const startInterventionAtButtonInterval = useRef();
    const endInterventionAtButtonInterval = useRef();
    const [stepCompleted, setStepCompleted] = useState(false);

    useEffect(() => {
        play();
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(startInterventionAtButtonInterval.current);
            clearInterval(endInterventionAtButtonInterval.current);
        }
    }, []);

    useEffect(() => {
        if (isNull(demo)) return;

        if (demo.current_step === enums.qualityInspection.STEP_DELIVERY) {
            nextStep();
        }
    }, [demo]);

    const play = () => {
        setTimeout(() => {
            window.sound.src = "/demo/step-9.1.mp3";
            window.sound.play();
        }, 500);

        setTimeout(() => {
            window.sound.src = "/demo/step-9.2.mp3";
            window.sound.play();
        }, 18500);

        setTimeout(() => {
            window.sound.src = "/demo/step-9.3.mp3";
            window.sound.play();
        }, 28500);

        setTimeout(() => {
            window.sound.src = "/demo/step-9.4.mp3";
            window.sound.play();

            setStepCompleted(true);
        }, 47500);

        withReservation();
        launchStartInterventionInput();
        launchEndInterventionInput();
        b2csduCheckbox();
    }


    const launchStartInterventionInput = () => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);

            startInterventionAtButtonInterval.current = setInterval(() => {
                const startInterventionInput = document.getElementById("start-intervention-input");

                if (startInterventionInput.style.background === "" || startInterventionInput.style.background === "rgb(0, 127, 254)") {
                    startInterventionInput.style.background = "red";
                    startInterventionInput.children[0].children[0].style.background = "red";
                } else {
                    startInterventionInput.style.background = "rgb(0, 127, 254)";
                    startInterventionInput.children[0].children[0].style.background = "rgb(0, 127, 254)";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(startInterventionAtButtonInterval.current);

                const startInterventionInput = document.getElementById("start-intervention-input");

                startInterventionInput.style.background = "rgb(0, 127, 254)";
                startInterventionInput.children[0].children[0].style.background = "rgb(0, 127, 254)";
            }, 3000);
        }, 18000);
    }

    const launchEndInterventionInput = () => {
        setTimeout(() => {
            endInterventionAtButtonInterval.current = setInterval(() => {
                const endInterventionInput = document.getElementById("end-intervention-input");

                if (endInterventionInput.style.background === "" || endInterventionInput.style.background === "rgb(0, 127, 254)") {
                    endInterventionInput.style.background = "red";
                    endInterventionInput.children[0].children[0].style.background = "red";
                } else {
                    endInterventionInput.style.background = "rgb(0, 127, 254)";
                    endInterventionInput.children[0].children[0].style.background = "rgb(0, 127, 254)";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(endInterventionAtButtonInterval.current);

                const endInterventionInput = document.getElementById("end-intervention-input");

                endInterventionInput.style.background = "rgb(0, 127, 254)";
                endInterventionInput.children[0].children[0].style.background = "rgb(0, 127, 254)";
            }, 3000);
        }, 20000);
    }


    const withReservation = () => {
        setTimeout(() => {
            const withReservationButton = document.getElementById("with-reservation-checkbox");

            withReservationButton.children[1].style.background = "yellow";

            setTimeout(async () => {
                withReservationButton.children[1].children[0].click();
                withReservationButton.children[1].style.background = "white";

                await putValue(document.getElementById("reservation"), "Réserves émises par le sociétaire");
            }, 500);
        }, 7000);

    }

    const b2csduCheckbox = () => {
        setTimeout(() => {
            const b2csduCheckbox = document.getElementById("b2csdu-checkbox");

            b2csduCheckbox.children[1].style.background = "yellow";

            setTimeout(() => {
                b2csduCheckbox.children[1].children[0].click();
                b2csduCheckbox.children[1].style.background = "white";

                setTimeout(async () => {
                    const orderCommentInput = document.getElementById("order-comment");

                    await putValue(orderCommentInput, "Remplacement à l'identique de la serrure");
                }, 500);
            }, 500);
        }, 32500);
    }

    const putValue = async (element, value, max = 500, min = 100) => {

        for (let i = 0; i < value.length; i++) {
            await new Promise(resolve => setTimeout(() => {
                element.value += value[i];

                resolve();
            }, Math.random() * (max - min) + min));
        }
    }

    if (stepCompleted) return null;

    return (
        <div className="bg-white/30 z-50 fixed h-full w-full max-w-2xl">

        </div>
    );
}