export default {
    STEP_INITIALIZED: 0,
    STEP_APPOINTMENT: 1,
    STEP_TRAVEL: 2,
    STEP_PICTURE_BEFORE: 3,
    STEP_QUOTATION: 4,
    STEP_CLIENT_SIGNATURE: 5,
    STEP_WORK: 6,
    STEP_PICTURE_AFTER: 7,
    STEP_DELIVERY: 8,
    SUB_STEP_DEFAULT: -1,
    SUB_STEP_ALL_TO_VALIDATE: 0,
    SUB_STEP_ALL_VALIDATED: 1,
    SUB_STEP_ALL_SIGNATURE: 2,
}
