import {init} from "../store/app";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import useApi from "../api/useApi";
import {useEffect} from "react";
import enums from "../enum/enums";

export default function useStore() {
    const dispatch = useDispatch();
    const api = useApi();
    const {token} = useParams();
    const demo = useSelector(state => state.demo);

    useEffect(() => {
        if (token !== "demo") return;

        const payload = {
            "current_sub_step": demo.current_sub_step,
            "left_at": null,
            "quotation_validate": demo.quotation_validate,
            "delivered_comment": null,
            "delivered_without_reservation": true,
            "id": 1,
            "intervention": {
                "suggestion_id": 1,
                "documents": {},
                "client_price": demo.current_step >= enums.qualityInspection.STEP_QUOTATION ? 1300 : 0,
                "created_at": "2023-08-04T08:56:44.724799Z",
                "appointment": demo.appointment,
                "artisan_id": 1,
                "token": "demo",
                "company_comment": null,
                "live_payment": false,
                "id": 96,
                "quotation": demo.current_step >= enums.qualityInspection.STEP_QUOTATION
                    ? {
                        vat: 10,
                        quotation_item: [
                            {
                                id: 1,
                                name: "Serrure",
                                price: 100
                            },
                            {
                                id: 2,
                                name: "Porte",
                                price: 1130
                            },
                            {
                                id: 3,
                                name: "Déplacement supplémentaire",
                                price: 70
                            }
                        ]
                    }
                    : null,
                "artisan_price": 135.0,
                "status": 0,
                "order": {
                    "work_id": 1,
                    "address": {
                        "line_1": "14 av de la liberté",
                        "line_2": "",
                        "lng": 1.9832116,
                        "city": "Paris",
                        "postcode": "75000",
                        "id": 56,
                        "lat": 43.53051199999999,
                        "line_3": null
                    },
                    "prescriber_id": 11,
                    "admin_phone": "0400000000",
                    "order_comment": "Porte claqué, bénéficiaire coincé dehors",
                    "created_at": "2023-08-01T08:15:52.551338Z",
                    "end_initiator": null,
                    "sell_price_main_work": 90.0,
                    "max_price": null,
                    "sell_price_shifting": 70.0,
                    "client": {
                        "phone_2": null,
                        "phone_1": "+33600000000",
                        "name": "Jean Dupont",
                        "id": 1,
                        "email": null
                    },
                    "id": 1,
                    "status": 2,
                    "order_items": [
                        {
                            "price": 70.0,
                            "default_item_id": 11,
                            "id": 137,
                            "enabled": true
                        },
                        {
                            "price": 90.0,
                            "default_item_id": 12,
                            "id": 138,
                            "enabled": true
                        }
                    ]
                }
            },
            "next_order_id": null,
            "arrived_at": null,
            "current_step": demo.current_step,
            "completed_works": null
        };

        dispatch(init(payload));
    }, [demo]);

    return {
        init: async () => {
            if (token === "demo") {
                const payload = {
                    "current_sub_step": demo.current_sub_step,
                    "left_at": null,
                    "quotation_validate": false,
                    "delivered_comment": null,
                    "delivered_without_reservation": true,
                    "id": 1,
                    "intervention": {
                        "suggestion_id": 1,
                        "documents": {},
                        "client_price": 0.0,
                        "created_at": "2023-08-04T08:56:44.724799Z",
                        "appointment": null,
                        "artisan_id": 1,
                        "token": "demo",
                        "company_comment": null,
                        "live_payment": false,
                        "id": 96,
                        "quotation": null,
                        "artisan_price": 135.0,
                        "status": 0,
                        "order": {
                            "work_id": 1,
                            "address": {
                                "line_1": "14 av de la liberté",
                                "line_2": "",
                                "lng": 1.9832116,
                                "city": "Paris",
                                "postcode": "75000",
                                "id": 56,
                                "lat": 43.53051199999999,
                                "line_3": null
                            },
                            "prescriber_id": 11,
                            "admin_phone": "0400000000",
                            "order_comment": "Porte claqué, bénéficiaire coincé dehors",
                            "created_at": "2023-08-01T08:15:52.551338Z",
                            "end_initiator": null,
                            "sell_price_main_work": 90.0,
                            "max_price": null,
                            "sell_price_shifting": 70.0,
                            "client": {
                                "phone_2": null,
                                "phone_1": "+33600000000",
                                "name": "Jean Dupont",
                                "id": 1,
                                "email": null
                            },
                            "id": 1,
                            "status": 2,
                            "order_items": [
                                {
                                    "price": 70.0,
                                    "default_item_id": 11,
                                    "id": 137,
                                    "enabled": true
                                },
                                {
                                    "price": 90.0,
                                    "default_item_id": 12,
                                    "id": 138,
                                    "enabled": true
                                }
                            ]
                        }
                    },
                    "next_order_id": null,
                    "arrived_at": null,
                    "current_step": demo.current_step,
                    "completed_works": null
                };

                dispatch(init(payload));
                return;
            }

            try {
                const response = await api.qualityInspection.getQualityInspection();
                dispatch(init(response));
            } catch (e) {
            }
        }
    }
}
