import suggestion from "./suggestion";
import qualityInspection from "./qualityInspection";
import requestedDocument from "./requestedDocument";
import order from "./order";
import intervention from "./intervention";

export default {
    suggestion: suggestion,
    qualityInspection: qualityInspection,
    requestedDocument: requestedDocument,
    order: order,
    intervention: intervention
}
