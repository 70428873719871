import mixin from "../../../../mixin/mixin";
import React, {useEffect, useState} from "react";
import useApi from "../../../../api/useApi";

export default function ClientPriceInvoiceDetail() {
    const api = useApi();
    const [clientPriceItems, setClientPriceItems] = useState([]);

    useEffect(async () => {
        const response = await api.qualityInspection.getClientPriceInvoice();

        setClientPriceItems(response);
    }, []);

    return (
        <div id="client-price-details">
            <table className="table-auto text-center border border-gray-700 my-3 w-full text-base">
                <thead>
                <tr className="border border-gray-700">
                    <th>Nom</th>
                    <th>PU</th>
                    <th>Qté</th>
                </tr>
                </thead>
                <tbody>
                {
                    clientPriceItems.map((clientPriceItem, index) => (
                        <tr key={index} className="border border-gray-700">
                            <td>{clientPriceItem.name}</td>
                            <td className="whitespace-nowrap text-center">{mixin.priceFormatter(clientPriceItem.price)} €</td>
                            <td className="whitespace-nowrap text-center">{mixin.priceFormatter(clientPriceItem.quantity)}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    );
}
