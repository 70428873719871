import NextStepButton from "./NextStepButton";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openConfirmPassword} from "../../../store/util";
import useStore from "../../../use/useStore";
import useAlert from "../../../use/useAlert";
import MapIcon from "../../util/icon/MapIcon";
import LinkIcon from "../../util/icon/LinkIcon";
import enums from "../../../enum/enums";
import useApi from "../../../api/useApi";

const PASSWORD = "123";

function StepTravel() {
    const store = useStore();
    const alert = useAlert();
    const api = useApi();
    const dispatch = useDispatch();
    const intervention = useSelector(state => state.app.intervention);
    const address = useSelector(state => state.app.address);
    const appointment = new Date(intervention.appointment);
    const [buttonLoading, setButtonLoading] = useState(false);

    const openGps = () => {
        window.open(
            `https://www.google.com/maps/dir/?api=1&origin=Current%20Location&destination=${address.lat},${address.lng}`,
            '_blank',
        );
    }

    const confirm = () => {
        dispatch(openConfirmPassword({
            title: "Vous confirmez votre arrivée chez le sociétaire ?",
            description: null,
            callback: [
                nextStep
            ],
            password: PASSWORD
        }));
    }

    const nextStep = async isConfirm => {
        if (!isConfirm) {
            return;
        }

        await store.init();

        setButtonLoading(true);
        const isSuccess = await api.qualityInspection.nextStep({
            quality_inspection: {
                current_step: enums.qualityInspection.STEP_TRAVEL
            },
        });

        if (isSuccess) {
            await store.init();
            return;
        }

        setButtonLoading(false);
        alert.launch("Une erreur s'est produite", "error");
    }


    return (
        <div>
            <div className="my-2 p-2 flex bg-blue-0 text-blue-1 rounded items-center mt-10" id="info-appointment">
                <svg xmlns="http://www.w3.org/2000/svg" className={'h-6 w-6 mx-3'} fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <div className="leading-tight">
                    Le sociétaire vous attend
                    le {appointment.toLocaleDateString("fr-Fr")} à {appointment.toLocaleTimeString("fr").split(':')[0]}h{appointment.toLocaleTimeString("fr").split(':')[1]}
                </div>
            </div>
            <div className="my-5 p-2 flex-column">
                <div className="flex flex-col my-2">
                    <button className="text-blue-1 font-bold py-2" id="location-link">
                        <a className="flex justify-center" onClick={() => openGps()}>
                            <p>
                                Ouvrir mon GPS
                            </p>
                            <LinkIcon/>
                        </a>
                    </button>
                </div>

                <div className="text-2xl text-center my-3">
                    <div className="flex justify-center mb-5">
                        <MapIcon size={20}/>
                    </div>

                    <p>
                        à {address.city} ({address.postcode})
                    </p>
                    <p>
                        {address.line_1}
                    </p>
                    <p>
                        {address.line_2}
                    </p>
                </div>

            </div>

            <NextStepButton
                title="Je confirme mon arrivée chez le sociétaire"
                callback={confirm}
                loading={buttonLoading}
            />
        </div>
    );
}

export default StepTravel;
