import Info from "../../../../util/modal/Info";
import React, {useEffect, useRef, useState} from "react";
import useStore from "../../../../../use/useStore";
import {useSelector} from "react-redux";
import isNull from "../../../../../mixin/isNull";
import NextStepButton from "../../NextStepButton";
import useApi from "../../../../../api/useApi";

const QUOTATION_VALIDATION = 0;
const PRESCRIBER_QUOTATION_VALIDATION = 1;

export default function () {
    const api = useApi();
    const store = useStore();
    const order = useSelector(state => state.app.order);
    const interval = useRef();
    const [waitingFor, setWaitingFor] = useState(QUOTATION_VALIDATION);

    useEffect(() => {
        interval.current = setInterval(async () => {
            const isValidated = await api.qualityInspection.isValidated();

            if (isValidated) {
                await store.init();
                clearInterval(interval.current)
                setWaitingFor(PRESCRIBER_QUOTATION_VALIDATION);

                interval.current = setInterval(async () => {
                    const isWaiting = await api.qualityInspection.isWaitingPrescriberValidation();

                    if (!isWaiting) {
                        await store.init();
                        clearInterval(interval.current)
                    }
                }, 3000);
            }
        }, 3000);

        return () => clearInterval(interval.current);
    }, []);

    return (
        <div>
            <Info/>

            <div className={'my-5 p-2 flex-column mt-10'}>
                <div className={'flex flex-col my-2'}>
                    {
                        !isNull(order) && !isNull(order.admin_phone) && waitingFor === QUOTATION_VALIDATION
                            ? (
                                <a href={`tel:${order.admin_phone}`}>
                                    <button
                                        className="text-white flex bg-red-500 justify-center rounded-lg border shadow-lg mt-5 w-full max-w-2xl p-3 text-sm w-full"
                                        id="quotation-validation-assistance-phone-button"
                                    >
                                            <span className="text-sm">
                                                    Contacter mon interlocuteur
                                            </span>
                                    </button>
                                </a>
                            )
                            : null
                    }

                    {
                        !isNull(order) && isNull(order.admin_phone) && waitingFor === QUOTATION_VALIDATION
                            ? (
                                <a href={`tel:${process.env.REACT_APP_ASSISTANCE_PHONE}`}>
                                    <button
                                        className="text-white flex bg-red-500 justify-center rounded-lg border shadow-lg mt-5 w-full max-w-2xl p-3 text-sm w-full">
                                            <span className="text-sm">
                                                    Contacter FAIRFAIR
                                            </span>
                                    </button>
                                </a>
                            )
                            : null
                    }

                    {
                        waitingFor === PRESCRIBER_QUOTATION_VALIDATION
                            ? (
                                <div
                                    className="bg-blue-1 w-full h-16 text-center text-white flex items-center justify-center mt-20">
                                    Nous attendons la validation de l'assistance, veuillez patienter.
                                </div>
                            )
                            : null
                    }
                </div>
            </div>

            <NextStepButton loading={true} style="fixed"/>
        </div>
    );
}