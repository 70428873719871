import axios from "axios";
import database from "../database/database";
import {useParams} from "react-router";

const prefix = '/service-emergency';

export default function () {
    const {token} = useParams();

    return {
        async getAllInterventionInProgress() {
            const accessToken = database.read(database.TABLE_AUTHENTICATION, "access_token");

            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `${prefix}/artisan/interventions/in_progress`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });

                return response.data;
            } catch (e) {
                if (e.response.status === 401) {
                    database.write(database.TABLE_AUTHENTICATION, "access_token");
                    document.location.href = '/';
                }

                return null;
            }
        },
        async sendPaymentSms() {
            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + `${prefix}/application/quality_inspections/by/token/${token}/payment_card`);
                return response.status === 204;
            } catch (e) {
                return false;
            }
        },
        async isClientInvoicePaid() {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `${prefix}/application/quality_inspections/by/token/${token}/is_payed`);
                return response.data;
            } catch (e) {
                return false;
            }
        },
        async updateCompanyComment(companyComment) {
            try {
                const response = await axios.patch(process.env.REACT_APP_API_URL + `${prefix}/application/interventions/${token}/company_comment`, {
                    intervention: {
                        company_comment: companyComment
                    }
                });
                return response.status === 204;
            } catch (e) {
                return false;
            }
        }
    }
}
