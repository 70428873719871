import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import mixin from "../../../../mixin/mixin";
import ClientPriceInvoiceDetail from "../quotation/ClientPriceInvoiceDetail";
import useEventDispatcher from "../../../../use/useEventDispatcher";
import event from "../../../../event/event";

export default function () {
    const eventDispatcher = useEventDispatcher();
    const intervention = useSelector(state => state.app.intervention);
    const quotation = useSelector(state => state.app.quotation);
    const [showArtisanPrice, setShowArtisanPrice] = useState(false);

    useEffect(() => {
        function handleShowArtisanPrice() {
            setShowArtisanPrice(true);
        }

        eventDispatcher.subscribe(event.SHOW_ARTISAN_PRICE, handleShowArtisanPrice);

        return () => eventDispatcher.unsubscribe(event.SHOW_ARTISAN_PRICE, handleShowArtisanPrice);
    }, []);

    useEffect(() => {
        function handleHideArtisanPrice() {
            setShowArtisanPrice(false);
        }

        eventDispatcher.subscribe(event.CLEAR_ARTISAN_PRICE, handleHideArtisanPrice);

        return () => eventDispatcher.unsubscribe(event.CLEAR_ARTISAN_PRICE, handleHideArtisanPrice);
    }, []);

    if (intervention.live_payment) {
        return (
            <div>
                <div className={!showArtisanPrice ? "hidden" : null}>
                    {getArtisanPrice(intervention, quotation)}
                </div>
                <div className={showArtisanPrice ? "hidden" : null}>
                    {getClientPrice(intervention, quotation)}

                    <div className={intervention.client_price > 0 && !intervention.live_payment ? null : "hidden"}>
                        <h4 className="font-bold">Merci de faire apparaitre sur la facture destinée au sociétaire :</h4>
                        <ClientPriceInvoiceDetail/>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div>
            <div className={!showArtisanPrice ? "hidden" : null}>
                {getArtisanPrice(intervention, quotation)}
            </div>
            <div className={showArtisanPrice || intervention.billing_mode === 1 ? "hidden" : null}>
                {getClientPrice(intervention, quotation)}

                <div className={intervention.client_price > 0 ? null : "hidden"}>
                    <h4 className="font-bold">Merci de faire apparaitre sur la facture destinée au sociétaire :</h4>
                    <ClientPriceInvoiceDetail/>
                </div>
            </div>
        </div>
    );
}

function getArtisanPrice(intervention, quotation) {

    if (intervention.artisan_price === 0) return null;


    return (
        <div className="bg-blue-0 text-blue-1 border-l-4 border-blue-1 rounded m-2 p-2">
            <p className="mb-2 text-xl">
                Facturez <b>FairFair</b> à hauteur de :
            </p>

            <p className="my-6 text-center text-2xl" id="artisan-price-info">
                <span
                    className="font-bold">{mixin.priceFormatter((intervention.live_payment ? intervention.artisan_price + intervention.client_price : intervention.artisan_price))}</span>€
                HT
                <span className="mx-3">|</span>
                <span
                    className="font-bold">{mixin.priceFormatter((intervention.live_payment ? intervention.artisan_price + intervention.client_price : intervention.artisan_price) * ((quotation.vat / 100) + 1))}</span>€
                TTC
            </p>
        </div>
    );
}

function getClientPrice(intervention, quotation) {

    if (intervention.client_price === 0) {
        return null;
    }

    if (intervention.live_payment) {
        return (
            <div>
                <div className="bg-blue-0 text-blue-1 border-l-4 border-blue-1 rounded m-2 p-2">
                    <p className="mb-2 text-xl">
                        Le <b>sociétaire</b> a été facturé pour un montant de :
                    </p>

                    <p className="my-6 text-center text-2xl">
                        <span
                            className="font-bold">{mixin.priceFormatter(intervention.client_price * ((quotation.vat / 100) + 1))}</span>€
                        TTC
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="bg-blue-0 text-blue-1 border-l-4 border-blue-1 rounded m-2 p-2">
                <p className="mb-2 text-xl">
                    Déduction faite de la PEC assistance, facturez le reste dû au <b>sociétaire</b> à hauteur de :
                </p>

                <p className="my-6 text-center text-2xl" id="client-price-info">
                    <span className="font-bold">{mixin.priceFormatter(intervention.client_price)}</span>€ HT
                    <span className="mx-3">|</span>
                    <span
                        className="font-bold">{mixin.priceFormatter(intervention.client_price * ((quotation.vat / 100) + 1))}</span>€
                    TTC
                </p>
            </div>
        </div>
    );
}
