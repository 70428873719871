import React, {Fragment, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";
import isNull from "../../mixin/isNull";
import enums from "../../enum/enums";
import CompanyComment from "./intervention/CompanyComment";

function Menu() {
    const navigate = useNavigate();
    const {token} = useParams();
    const cancelButtonRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [openCompanyCommentMenu, setOpenCompanyCommentMenu] = useState(false);

    const order = useSelector(state => state.app.order);
    const client = useSelector(state => state.app.client);
    const address = useSelector(state => state.app.address);
    const qualityInspection = useSelector(state => state.app.qualityInspection);


    if (isNull(client.phone_1)) return null;


    const openGps = () => {
        window.open(
            `https://www.google.com/maps/dir/?api=1&origin=Current%20Location&destination=${address.lat},${address.lng}`,
            '_blank',
        );
    }

    return (
        <div className="w-full">
            <div className="flex justify-between">
                <div className="bg-blue-0 p-2 rounded text-sm font-bold flex text-blue-1"
                     type="button"
                     style={{transition: 'all .15s ease'}}
                     onClick={() => setOpen(!open)}
                     id="menu"
                >
                    <span className="my-auto">MENU</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
                    </svg>
                </div>


                <button className="bg-orange-100 p-2 rounded text-sm font-bold flex text-orange-500"
                        type="button"
                        style={{transition: 'all .15s ease'}}
                        onClick={() => setOpenCompanyCommentMenu(true)}
                        id="let-message-button"
                >
                    <span className="my-auto">LAISSER UN MESSAGE</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         strokeWidth="2" stroke="currentColor" className="w-6 h-6 ml-0.5">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"/>
                    </svg>

                </button>

            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className={'fixed z-10 inset-0 overflow-y-auto w-full'} initialFocus={cancelButtonRef}
                        onClose={() => setOpen(false)}>
                    <div
                        className={'flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className={'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'}/>
                        </Transition.Child>

                        <span className={'hidden sm:inline-block sm:align-middle sm:h-screen'} aria-hidden="true">
                        &#8203;
                    </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div
                                className={'inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'}>
                                <div className={'bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'}>
                                    <div className={'sm:flex sm:items-start'}>
                                        <div
                                            className={'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-600"
                                                 fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                            </svg>
                                        </div>
                                        <div className={'mt-3 sm:mt-0 sm:ml-4 text-center'}>
                                            <div className="mt-2">
                                                <div className="p-3">
                                                    <h6 className="font-bold text-2xl mb-2">Description</h6>

                                                    {order.order_comment}
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <div className="p-3">
                                                    <h6 className="font-bold text-2xl mb-2">Adresse</h6>

                                                    {address.line_1}<br/>
                                                    {
                                                        address.line_2 !== null
                                                            ? (<>{address.line_2}<br/></>)
                                                            : null
                                                    }
                                                    {
                                                        address.line_3 !== null
                                                            ? (<>{address.line_3}<br/></>)
                                                            : null
                                                    }
                                                    {address.postcode} {address.city}<br/>

                                                </div>
                                            </div>
                                            {
                                                !isNull(qualityInspection)
                                                && (qualityInspection.current_step === enums.qualityInspection.STEP_QUOTATION
                                                    || qualityInspection.current_step === enums.qualityInspection.STEP_CLIENT_SIGNATURE)
                                                    ? (
                                                        <button
                                                            id="open-menu-update-quotation"
                                                            className="text-white flex bg-orange-500 justify-center border shadow-lg mt-5 w-full max-w-2xl p-3 text-sm w-full"
                                                            onClick={() => {
                                                                navigate(`/i/${token}/update/quotation`);
                                                                setOpen(false);
                                                            }}>
                                                            <span className="text-sm">
                                                                Modifier mon devis
                                                            </span>
                                                        </button>
                                                    )
                                                    : null
                                            }
                                            <div className={'flex flex-col my-2'}>
                                                <button className={'bg-blue-600 text-white p-2'}>
                                                    <a className={'flex justify-center'} onClick={() => openGps()}>
                                                        <p>
                                                            Ouvrir mon GPS
                                                        </p>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className={'h-6 w-6'}
                                                             fill="none"
                                                             viewBox="0 0 24 24"
                                                             stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  strokeWidth={2}
                                                                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                                                        </svg>
                                                    </a>
                                                </button>
                                            </div>
                                            <hr/>
                                            <div className="mt-2">
                                                <div className={'flex flex-col my-2'}>
                                                    <button className={'bg-blue-600 text-white p-2'}
                                                            id="open-menu-client-phone-1">
                                                        <a href={'tel:' + client.phone_1}
                                                           className={'flex justify-center'}>
                                                            <svg className={'w-6 h-6 mx-1'} fill="currentColor"
                                                                 viewBox="0 0 20 20"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"/>
                                                            </svg>
                                                            <p>
                                                                Appelez <b>{client.name}</b><br/>
                                                                {client.phone_1}
                                                            </p>
                                                        </a>
                                                    </button>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className={'flex flex-col my-2'}>
                                                <button className={'bg-blue-600 text-white p-2'}>
                                                    <a href={'tel:' + process.env.REACT_APP_ASSISTANCE_PHONE}
                                                       className={'flex justify-center'}>
                                                        <svg className={'w-6 h-6 mx-1'} fill="currentColor"
                                                             viewBox="0 0 20 20"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"/>
                                                        </svg>
                                                        <p>
                                                            Appelez l'assistance
                                                        </p>
                                                    </a>
                                                </button>
                                            </div>
                                            <hr/>
                                            <div className="mt-0">
                                                <a href={'/'} target={'_blank'}>
                                                    <button
                                                        className={`text-white flex bg-blue-600 justify-center border shadow-lg  mt-5 w-full p-3`}>
                                                        Mon espace
                                                    </button>
                                                </a>
                                            </div>
                                            {/*
                                                <div className="mt-10">
                                                <div className={'flex flex-col my-2'}>
                                                <button className={'bg-blue-600 rounded-lg text-white p-2'}>
                                                <a href={'https://' + process.env.REACT_APP_ARTISAN_APP_URL + '/i/' + intervention.token}
                                                className={'flex justify-center'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6"
                                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                                                </svg>
                                                <p>
                                                Retourner sur la version stable
                                                </p>
                                                </a>
                                                </button>
                                                </div>
                                                </div>
                                                */}
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpen(false)}
                                    >
                                        Fermer
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={openCompanyCommentMenu} as={Fragment}>
                <Dialog as="div" className={'fixed z-10 inset-0 overflow-y-auto w-full'} initialFocus={cancelButtonRef}
                        onClose={() => setOpenCompanyCommentMenu(false)}>
                    <div
                        className={'flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className={'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'}/>
                        </Transition.Child>

                        <span className={'hidden sm:inline-block sm:align-middle sm:h-screen'} aria-hidden="true">
                        &#8203;
                    </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div
                                className={'inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'}>
                                <div className={'bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'}>
                                    <CompanyComment onSuccess={() => setOpenCompanyCommentMenu(false)}/>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpenCompanyCommentMenu(false)}
                                    >
                                        Fermer
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
}

export default Menu;
