import Confirm from "../../component/util/modal/Confirm";
import React, {useEffect} from "react";
import Quotation from "../../component/tracking/quotation/Quotation";
import {useNavigate, useParams} from "react-router";
import Header from "../../component/tracking/Header";
import {useSelector} from "react-redux";
import isNull from "../../mixin/isNull";
import useStore from "../../use/useStore";

export default function () {
    const store = useStore();
    const {token} = useParams();
    const navigate = useNavigate();
    const order = useSelector(state => state.app.order);

    useEffect(() => {
        store.init();
    }, []);
    
    if (isNull(order)) return null;

    return (
        <div style={{marginBottom: "5rem"}}>
            <Confirm/>
            <Header
                orderId={order.id}
                title={"Modifier mon devis"}
                progressPercent={100}
            />

            <Quotation onTerminate={() => navigate(`/i/${token}`)}/>
        </div>
    );
}