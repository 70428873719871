import {useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import isNull from "../../../mixin/isNull";
import enums from "../../../enum/enums";

export default function ({nextStep}) {
    const demo = useSelector(state => state.demo);
    const completedWorksInputInterval = useRef();
    const menuInterval = useRef();
    const openMenuUpdateQuotationInterval = useRef();
    const [stepCompleted, setStepCompleted] = useState(false);

    useEffect(() => {
        play();
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(completedWorksInputInterval.current);
            clearInterval(menuInterval.current);
            clearInterval(openMenuUpdateQuotationInterval.current);
        }
    }, []);

    useEffect(() => {
        if (isNull(demo)) return;

        if (demo.current_step === enums.qualityInspection.STEP_WORK) {
            nextStep();
        }
    }, [demo]);

    const play = () => {
        setTimeout(() => {
            window.sound.src = "/demo/step-7.1.mp3";
            window.sound.play();
        }, 500);

        setTimeout(() => {
            window.sound.src = "/demo/step-7.2.mp3";
            window.sound.play();
        }, 25000);

        setTimeout(() => {
            window.sound.src = "/demo/step-7.3.mp3";
            window.sound.play();
        }, 32000);

        setTimeout(() => {
            window.sound.src = "/demo/step-7.4.mp3";
            window.sound.play();
        }, 37000);

        launchCompletedWorksInput();
        launchMenu();
        launchOpenMenuUpdateQuotation();
    }


    const launchCompletedWorksInput = () => {
        setTimeout(() => {
            const infoButton = document.getElementById("info-button");

            if (!isNull(infoButton)) {
                infoButton.click();
            }

            completedWorksInputInterval.current = setInterval(() => {
                const completedWorksInput = document.getElementById("completed-works-input");

                completedWorksInput.click();

                if (completedWorksInput.style.background === "" || completedWorksInput.style.background === "rgb(243, 244, 246)") {
                    completedWorksInput.style.background = "red";
                } else {
                    completedWorksInput.style.background = "rgb(243 244 246)";
                }
            }, 500);

            setTimeout(() => {
                clearInterval(completedWorksInputInterval.current);

                const completedWorksInput = document.getElementById("completed-works-input");

                completedWorksInput.style.background = "rgb(243 244 246)";

                setTimeout(async () => {
                    await putValue(completedWorksInput, "Ouverture de porte avec une radio. Pose d'un canon provisoire.");
                }, 1000);
            }, 4000);
        }, 1000);
    }

    const launchMenu = () => {
        setTimeout(() => {
            menuInterval.current = setInterval(() => {
                const menu = document.getElementById("menu");

                if (menu.style.background === "" || menu.style.background === "rgb(234, 242, 255)") {
                    menu.style.background = "red";
                    menu.style.color = "white";
                } else {
                    menu.style.background = "rgb(234, 242, 255)";
                    menu.style.color = "rgb(0, 127, 254)";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(menuInterval.current);

                const menu = document.getElementById("menu");

                menu.style.background = "rgb(234, 242, 255)";
                menu.style.color = "rgb(0, 127, 254)";
            }, 5000);

        }, 27000);
    }

    const launchOpenMenuUpdateQuotation = () => {
        setTimeout(() => {
            const menu = document.getElementById("menu");
            menu.click();

            openMenuUpdateQuotationInterval.current = setInterval(() => {
                let openMenuUpdateQuotation = document.getElementById("open-menu-update-quotation");

                if (isNull(openMenuUpdateQuotation)) {
                    menu.click();

                    openMenuUpdateQuotation = document.getElementById("open-menu-update-quotation");
                }

                if (openMenuUpdateQuotation.style.background === "" || openMenuUpdateQuotation.style.background === "rgb(249, 115, 22)") {
                    openMenuUpdateQuotation.style.background = "red";
                } else {
                    openMenuUpdateQuotation.style.background = "rgb(249, 115, 22)";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(openMenuUpdateQuotationInterval.current);

                let openMenuUpdateQuotation = document.getElementById("open-menu-update-quotation");

                if (isNull(openMenuUpdateQuotation)) {
                    menu.click();

                    openMenuUpdateQuotation = document.getElementById("open-menu-update-quotation");
                }

                openMenuUpdateQuotation.style.background = "rgb(249, 115, 22)";
                menu.click();
                setStepCompleted(true);
            }, 5000);
        }, 32000);
    }


    const putValue = async (element, value, max = 500, min = 100) => {

        for (let i = 0; i < value.length; i++) {
            await new Promise(resolve => setTimeout(() => {
                element.value += value[i];

                resolve();
            }, Math.random() * (max - min) + min));
        }
    }

    if (stepCompleted) return null;

    return (
        <div className="bg-white/30 fixed z-50 h-full w-full max-w-2xl">

        </div>
    );
}