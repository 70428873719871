import React, {useEffect, useRef, useState} from "react";
import NextStepButton from "./NextStepButton";
import {useDispatch, useSelector} from "react-redux";
import useStore from "../../../use/useStore";
import mixin from "../../../mixin/mixin";
import {openConfirm} from "../../../store/util";
import useAlert from "../../../use/useAlert";
import specificity from "../../../specificity/specificity";
import isNull from "../../../mixin/isNull";
import useApi from "../../../api/useApi";
import enums from "../../../enum/enums";

function StepDelivery() {
    const api = useApi();
    const alert = useAlert();
    const dispatch = useDispatch();
    const store = useStore();
    const qualityInspection = useSelector(state => state.app.qualityInspection);
    const client = useSelector(state => state.app.client);
    const order = useSelector(state => state.app.order);
    const [loading, setLoading] = useState(false);
    const [isWithReservation, setWithReservation] = useState(qualityInspection.delivered_without_reservation !== null ? !qualityInspection.delivered_without_reservation : false);
    const [arrivedAt, setArrivedAt] = useState(qualityInspection.arrived_at);
    const [leftAt, setLeftAt] = useState(new Date());
    const [showOrderComment, setShowOrderComment] = useState(false);
    const deliveredCommentInput = useRef();
    const orderCommentInput = useRef();
    const requestNextOrderInput = useRef();
    const notRequestNextOrderInput = useRef();

    useEffect(() => {
        if (showOrderComment) {
            const element = document.getElementById("order-comment");
            element.focus();
            element.scrollIntoView();
        }
    }, [showOrderComment]);

    useEffect(() => {
        if (isWithReservation) {
            const element = document.getElementById("reservation");
            element.focus();
            element.scrollIntoView();
        }
    }, [isWithReservation]);

    const nextStep = async (isConfirm) => {
        if (!isConfirm) {
            return;
        }

        if (!requestNextOrderInput.current.checked && !notRequestNextOrderInput.current.checked) {
            alert.launch("Merci de préciser si des réparations définitives sont nécessaires", "warning");
            return;
        }

        if (isWithReservation && (deliveredCommentInput.current.value === "" || isNull(deliveredCommentInput.current.value))) {
            alert.launch("Merci de mentionner vos reserves", "warning");
            return;
        }

        setLoading(true);

        await api.qualityInspection.patchDeliveredWithoutReservation(!isWithReservation);
        await api.qualityInspection.patchDeliveredComment(!isWithReservation ? null : deliveredCommentInput.current.value);
        await api.qualityInspection.patchArrivedAt(arrivedAt);
        await api.qualityInspection.patchLeftAt(leftAt);
        await api.qualityInspection.patchRequestNextOrder(requestNextOrderInput.current.checked, orderCommentInput.current?.value);

        await api.qualityInspection.nextStep({
            quality_inspection: {
                current_step: enums.qualityInspection.STEP_DELIVERY
            }
        });

        await store.init();
    }

    const openModal = () => {
        dispatch(
            openConfirm({
                title: "Attention",
                description: "Les heures d'arrivée et de départ doivent être cohérentes avec le nombre d'heures de main-d'oeuvre renseignées dans le devis.",
                callback: [nextStep]
            })
        )
    }


    return (
        <div>
            <div className="max-w-2xl p-1">
                <button
                    className={`text-white flex bg-orange-500 justify-center rounded-lg border shadow-lg mt-5 w-full max-w-2xl p-3 text-sm w-full`}
                    disabled={true}>
                    <span className="text-base">
                        Procès-verbal numérisé
                    </span>
                </button>
                Le sociétaire va recevoir un SMS pour signer électroniquement son procès-verbal de fin d'intervention.
            </div>

            <div className="max-w-2xl p-1 mt-5">
                <div>
                    <div id="with-reservation-checkbox">
                        Je soussigné {client.name}, déclare que la réception des travaux est
                        <div className="flex justify-start w-full">
                            <input type="checkbox"
                                   id="without-reservation"
                                   checked={!isWithReservation}
                                   className="w-6 my-auto"
                                   onChange={() => setWithReservation(!isWithReservation)}/>
                            <label htmlFor="without-reservation">Sans réserve</label>
                        </div>
                        <div className="flex justify-start w-full">
                            <input type="checkbox"
                                   id="with-reservation"
                                   className="w-6 my-auto"
                                   checked={isWithReservation}
                                   onChange={() => setWithReservation(!isWithReservation)}/>
                            <label htmlFor="with-reservation" className="p-0">Avec réserve</label>
                        </div>
                        {
                            isWithReservation
                                ? (
                                    <div className="mt-5">
                                        <label>Précisez les réserves :</label>
                                        <textarea
                                            id="reservation"
                                            ref={deliveredCommentInput}
                                            className="input-text border w-full p-1"
                                            placeholder="Laissez vide si vous n'émettez pas de reserve"
                                            defaultValue={qualityInspection.delivered_comment}
                                        />
                                    </div>
                                )
                                : null
                        }
                    </div>
                    <div className="mt-5">
                        <label>Début d'intervention
                            le {mixin.dateFormatter(arrivedAt)}</label>
                        <div className="flex justify-center">
                            <div
                                className='my-2 bg-blue-1 p-2 rounded-lg text-white max-w-sm'
                                id="start-intervention-input"
                            >
                                <div className="justify-center">
                                    <input
                                        type='datetime-local'
                                        className='w-full m-0 bg-blue-1'
                                        onChange={e => setArrivedAt(new Date(e.target.value))}
                                    />
                                </div>
                            </div>
                        </div>
                        <label>Fin d'intervention
                            le {mixin.dateFormatter(leftAt)}</label>
                        <div className="flex justify-center">
                            <div
                                className='my-2 bg-blue-1 p-2 rounded-lg text-white max-w-sm'
                                id="end-intervention-input"
                            >
                                <div className="justify-center">
                                    <input
                                        type='datetime-local'
                                        className='w-full m-0 bg-blue-1'
                                        onChange={e => setLeftAt(new Date(e.target.value))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        specificity.allowSecondMission(order.prescriber_id)
                            ? (
                                <div className="mt-5" id="b2csdu-checkbox">
                                    <div>
                                        Le sociétaire souhaite des travaux complémentaires ?
                                    </div>
                                    <div className="flex justify-start items-center w-full mt-10">
                                        <input
                                            type="radio"
                                            name="request-next-order"
                                            id="request-next-order"
                                            ref={requestNextOrderInput}
                                            className="w-6 h-6 my-auto mx-5"
                                            defaultChecked={false}
                                            onClick={e => {
                                                setShowOrderComment(e.target.checked)
                                            }}
                                        />
                                        <label htmlFor="request-next-order"
                                               className="font-bold mr-5 text-base text-left"
                                        >
                                            OUI
                                        </label>
                                    </div>
                                    <div className="flex justify-start items-center w-full mt-10">
                                        <input
                                            type="radio"
                                            name="request-next-order"
                                            id="not-request-next-order"
                                            ref={notRequestNextOrderInput}
                                            className="w-6 h-6 my-auto mx-5"
                                            defaultChecked={false}
                                            onClick={e => {
                                                setShowOrderComment(false)
                                            }}
                                        />
                                        <label htmlFor="not-request-next-order"
                                               className="font-bold mr-5 text-base text-left"
                                        >
                                            NON
                                        </label>
                                    </div>
                                    {
                                        showOrderComment
                                            ? (
                                                <div className="w-full mt-10 mb-10">
                                                    <label className="mt-5">Décrivez les travaux souhaités par le
                                                        sociétaire</label>
                                                    <textarea
                                                        id="order-comment"
                                                        ref={orderCommentInput}
                                                        placeholder="Description des travaux souhaités par le sociétaire ..."
                                                        className="w-full bg-gray-100 p-1"
                                                    />
                                                </div>
                                            )
                                            : null
                                    }
                                </div>
                            )
                            : null
                    }
                </div>

            </div>

            <div className={"flex space-x-2"}>
                <NextStepButton
                    title="Envoyer le SMS"
                    callback={openModal}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default StepDelivery;
