import React, {useRef} from "react";
import useAlert from "../../../../use/useAlert";

export default function ({backCallback, updateCallback, quotationItem}) {
    const alert = useAlert();
    const nameInput = useRef();
    const descriptionInput = useRef();
    const priceInput = useRef();
    const quantityInput = useRef();
    const submit = () => {
        const data = {};

        if (quotationItem.default_item_id === null && quotationItem.standardized_item_id === null && !quotationItem.is_managed && nameInput.current === "") {
            alert.launch("Merci de renseigner le nom de la pièce", "warning");
            return;
        }

        if (quotationItem.default_item_id === null && quotationItem.standardized_item_id === null && !quotationItem.is_managed && priceInput.current === "") {
            alert.launch("Merci de renseigner le prix de la pièce", "warning");
            return;
        }

        if (quantityInput.current === "") {
            alert.launch("Merci de renseigner la quantité", "warning");
            return;
        }

        if (quotationItem.default_item_id === null && quotationItem.standardized_item_id === null && !quotationItem.is_managed) {
            updateCallback({
                name: nameInput.current.value,
                description: descriptionInput.current.value,
                price: priceInput.current.value,
                quantity: quantityInput.current.value,

            });
        } else {
            updateCallback({
                quantity: quantityInput.current.value,
            });
        }
    }

    return (
        <div>

            <div>
                {
                    quotationItem.default_item_id === null && quotationItem.standardized_item_id === null && !quotationItem.is_managed
                        ? (
                            <div>
                                <label>Nom de la pièce</label>
                                <input
                                    placeholder="Nom de la pièce"
                                    defaultValue={quotationItem.name}
                                    ref={nameInput}
                                />
                            </div>
                        )
                        : null
                }

                {
                    quotationItem.default_item_id === null && quotationItem.standardized_item_id === null && !quotationItem.is_managed
                        ? (
                            <div>
                                <label>Description de la pièce</label>
                                <input
                                    placeholder="Marque, Référence, Taille ..."
                                    defaultValue={quotationItem.description}
                                    ref={descriptionInput}
                                />
                            </div>
                        )
                        : null
                }

                {
                    quotationItem.default_item_id === null && quotationItem.standardized_item_id === null && !quotationItem.is_managed
                        ? (
                            <div>
                                <label>Prix HT</label>
                                <input
                                    type="number"
                                    step="0.01"
                                    placeholder="Prix HT"
                                    defaultValue={quotationItem.price}
                                    ref={priceInput}
                                />
                            </div>
                        )
                        : null
                }

                <label>Quantité</label>
                <input
                    type="number"
                    step="0.5"
                    placeholder="Quantité"
                    defaultValue={quotationItem.quantity}
                    ref={quantityInput}
                />

                <div className="flex space-x-2">
                    <button className="bg-red-600 flex flex-1 p-2 text-white font-semibold justify-center"
                            onClick={() => backCallback()}>
                        Retour
                        <svg className={'w-6 h-6'} fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"/>
                        </svg>
                    </button>
                    <button
                        id="update-quotation-item-button"
                        onClick={submit}
                        className={'bg-blue-1 p-2 flex flex-1 text-white font-semibold justify-center'}>
                        Modifier
                        <svg className={'w-6 h-6'} fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
}
