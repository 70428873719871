import {useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import isNull from "../../../mixin/isNull";
import enums from "../../../enum/enums";

export default function ({nextStep}) {
    const demo = useSelector(state => state.demo);
    const selectStandardizedItemInterval = useRef();
    const addShiftingInterval = useRef();
    const addDoorInterval = useRef();
    const [stepCompleted, setStepCompleted] = useState(false);

    useEffect(() => {
        play();
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(selectStandardizedItemInterval.current);
            clearInterval(addDoorInterval.current);
            clearInterval(addShiftingInterval.current);
        }
    }, []);

    useEffect(() => {
        if (isNull(demo)) return;

        if (demo.current_step === enums.qualityInspection.STEP_QUOTATION) {
            nextStep();
        }
    }, [demo]);

    const play = () => {
        setTimeout(() => {
            window.sound.src = "/demo/step-4.1.mp3";
            window.sound.play();
        }, 500);

        setTimeout(() => {
            window.sound.src = "/demo/step-4.2.mp3";
            window.sound.play();
        }, 12500);

        setTimeout(() => {
            window.sound.src = "/demo/step-4.3.mp3";
            window.sound.play();
        }, 24500);

        setTimeout(() => {
            window.sound.src = "/demo/step-4.4.mp3";
            window.sound.play();
        }, 46000);

        setTimeout(() => {
            window.sound.src = "/demo/step-4.5.mp3";
            window.sound.play();
        }, 63000);

        setTimeout(() => {
            window.sound.src = "/demo/step-4.6.mp3";
            window.sound.play();
        }, 73000);

        setTimeout(() => {
            window.sound.src = "/demo/step-4.7.mp3";
            window.sound.play();
        }, 80000);

        launchSelectStandardizedItem();
        launchAutoResearch();
        launchAddDoor();
        launchAddShifting();
        launchUpdateQuantity();
        completeEmail();
    }


    const launchSelectStandardizedItem = () => {
        setTimeout(() => {
            setTimeout(() => {
                selectStandardizedItemInterval.current = setInterval(() => {
                    const selectStandardizedItem = document.getElementById("search-field");

                    if (selectStandardizedItem.style.background === "" || selectStandardizedItem.style.background === "white") {
                        selectStandardizedItem.style.background = "red";
                        selectStandardizedItem.style.color = "white";
                    } else {
                        selectStandardizedItem.style.background = "white";
                        selectStandardizedItem.style.color = "black";
                    }

                }, 500);
            }, 1000);

            setTimeout(() => {
                clearInterval(selectStandardizedItemInterval.current);

                const selectStandardizedItem = document.getElementById("search-field");

                selectStandardizedItem.style.background = "white";
                selectStandardizedItem.style.color = "black";
            }, 5000);
        }, 17000);
    }

    const launchAutoResearch = async () => {
        setTimeout(async () => {
            const selectStandardizedItem = document.getElementById("search-field");

            selectStandardizedItem.click();

            await putValue(selectStandardizedItem, "serrure");

            const options = document.getElementById("select-standardized-item-option");

            setTimeout(() => {
                options.children[0].focus();
                options.children[0].style.background = "yellow";

                setTimeout(() => {
                    options.children[0].style.background = "white";
                }, 100);

                setTimeout(() => {
                    options.children[0].click();
                }, 200);
            }, 500);
        }, 22000);
    }

    const launchAddDoor = async () => {
        setTimeout(async () => {
            const selectStandardizedItem = document.getElementById("search-field");

            selectStandardizedItem.value = "";

            selectStandardizedItem.click();

            await putValue(selectStandardizedItem, "porte");

            const options = document.getElementById("select-standardized-item-option");

            setTimeout(() => {
                options.children[0].focus();
                options.children[0].style.background = "yellow";

                setTimeout(() => {
                    options.children[0].style.background = "white";
                }, 100);

                setTimeout(() => {
                    options.children[0].click();

                    setTimeout(() => {
                        const alertOutOfStandardButton = document.getElementById("alert-out-of-standard-button")

                        alertOutOfStandardButton.click();

                        setTimeout(async () => {
                            const inputs = document.getElementsByTagName("input");

                            await putValue(inputs[0], "Porte");
                            await putValue(inputs[1], "Porte d'entrée en aluminium");
                            await putValue(inputs[2], "1130");

                            setTimeout(() => {
                                const button = document.getElementById("create-quotation-button-submit");

                                button.style.background = "yellow";

                                setTimeout(() => {
                                    button.click();
                                }, 300);
                            }, 500);
                        }, 300);
                    }, 2500);
                }, 200);
            }, 500);
        }, 27500);
    }

    const launchAddShifting = () => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
            const addShiftingButton = document.getElementById("add-shifting-button");

            setTimeout(() => {
                addShiftingButton.style.background = "yellow";

                setTimeout(() => {
                    addShiftingButton.click();
                }, 200);
            }, 2000);
        }, 55000);
    }

    const launchUpdateQuantity = () => {
        setTimeout(() => {
            const quotationItem3 = document.getElementById("quotation-item-4");

            quotationItem3.style.background = "yellow";

            setTimeout(() => {
                quotationItem3.click();

                setTimeout(() => {
                    const inputs = document.getElementsByTagName("input");

                    inputs[0].value = "";

                    setTimeout(() => {
                        inputs[0].value = "2";

                        const updateQuotationItemButton = document.getElementById("update-quotation-item-button");

                        updateQuotationItemButton.style.background = "yellow";

                        setTimeout(() => {
                            updateQuotationItemButton.click();
                        }, 1000);
                    }, 500);
                }, 2000);
            }, 300);
        }, 67000);
    }

    const completeEmail = async () => {
        setTimeout(async () => {
            window.scrollTo(0, document.body.scrollHeight);
            const clientEmail = document.getElementById("client-email");

            clientEmail.focus();
            clientEmail.click();

            await putValue(clientEmail, "jean.dupont@gmail.com");

            setStepCompleted(true);
        }, 73000);
    }

    const putValue = async (element, value, max = 500, min = 100) => {

        for (let i = 0; i < value.length; i++) {
            await new Promise(resolve => setTimeout(() => {
                element.value += value[i];

                resolve();
            }, Math.random() * (max - min) + min));
        }
    }

    if (stepCompleted) return null;

    return (
        <div className="bg-white/30 fixed z-50 h-full w-full max-w-2xl">

        </div>
    );
}