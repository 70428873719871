import React from "react";

export default function () {
    return (
        <>
            <div className={'text-center mt-20 uppercase text-2xl'}>
                Intervention annulée
            </div>
            <div className={'flex justify-center mt-10'}>
                <a href={'to:' + process.env.REACT_APP_ASSISTANCE_PHONE}>
                    <button className={'bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg'}>
                        Contacter le plateau
                    </button>
                </a>
            </div>
        </>
    );
}
