import Confirm from "../../component/util/modal/Confirm";
import Header from "../../component/tracking/Header";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ButtonLoader from "../../component/util/loader/ButtonLoader";
import enums from "../../enum/enums";
import StepAppointment from "../../component/tracking/intervention/StepAppointment";
import StepTravel from "../../component/tracking/intervention/StepTravel";
import StepPictureBefore from "../../component/tracking/intervention/StepPictureBefore";
import StepQuotation from "../../component/tracking/intervention/StepQuotation";
import StepWork from "../../component/tracking/intervention/StepWork";
import StepPictureAfter from "../../component/tracking/intervention/StepPictureAfter";
import StepDelivery from "../../component/tracking/intervention/StepDelivery";
import Finally from "../../component/tracking/intervention/Finally";
import ExternalAbort from "../../component/tracking/intervention/ExternalAbort";
import InternalAbort from "../../component/tracking/intervention/InternalAbort";
import useStore from "../../use/useStore";
import useGeolocation from "../../use/useGeolocation";
import ConfirmPassword from "../../component/util/modal/ConfirmPassword";
import isNull from "../../mixin/isNull";
import SubStepQuotationToValidate
    from "../../component/tracking/intervention/sub-step/client-signature/SubStepQuotationToValidate";
import SubStepCertificateClientPriceSignature
    from "../../component/tracking/intervention/sub-step/client-signature/SubStepCertificateClientPriceSignature";
import SubStepOnlinePayment from "../../component/tracking/intervention/sub-step/client-signature/SubStepOnlinePayment";
import Demo from "../../component/tracking/demo/Demo";

function QualityInspection() {
    const geolocation = useGeolocation();
    const store = useStore();
    const qualityInspection = useSelector(state => state.app.qualityInspection);
    const order = useSelector(state => state.app.order);
    const intervention = useSelector(state => state.app.intervention);
    const [step, setStep] = useState({
        title: null,
        progressPercent: 10,
        component: null
    });

    useEffect(() => {
        store.init();
    }, []);

    useEffect(() => {
        if (isNull(qualityInspection) || isNull(qualityInspection.current_step) || isNull(intervention)) return;

        setStep(getStepConfig(qualityInspection, intervention));
    }, [qualityInspection, intervention]);

    useEffect(() => {
        if (isNull(intervention)) return;

        geolocation(intervention.artisan_id);
    }, [intervention]);

    return (
        <div style={{marginBottom: "5rem"}}>
            <Demo/>
            <Confirm/>
            <ConfirmPassword/>
            <Header
                orderId={order.id}
                title={step.title !== null ? step.title : 'Chargement ...'}
                progressPercent={step.progressPercent !== null ? step.progressPercent : 0}
            />

            {
                step.component !== null && order.end_initiator === null && intervention.status !== enums.intervention.STATUS_FAILED
                    ? <div className={'text-lg'}>{step.component}</div>
                    : null
            }

            {
                step.component === null && order.end_initiator === null
                    ? (
                        <div className={'flex justify-center mt-20'}>
                            <ButtonLoader text={'Chargement'} background={'bg-green-700'}/>
                        </div>
                    )
                    : null
            }

            {
                order.end_initiator === enums.order.END_INITIATOR_CLIENT || order.end_initiator === enums.order.END_INITIATOR_PRESCRIBER
                    ? <ExternalAbort/>
                    : null
            }

            {
                order.end_initiator === enums.order.END_INITIATOR_INTERNAL
                || (intervention.status === enums.intervention.STATUS_FAILED && order.end_initiator !== enums.order.END_INITIATOR_CLIENT && order.end_initiator !== enums.order.END_INITIATOR_PRESCRIBER)
                    ? <InternalAbort/>
                    : null
            }
        </div>
    );
}

function getStepConfig(qualityInspection, intervention) {

    switch (qualityInspection.current_step) {
        case enums.qualityInspection.STEP_INITIALIZED:
            return {
                title: '1. Rendez-vous',
                progressPercent: 11,
                component: <StepAppointment/>
            };
        case enums.qualityInspection.STEP_APPOINTMENT:
            return {
                title: '2. Déplacement',
                progressPercent: 22,
                component: <StepTravel/>
            };
        case enums.qualityInspection.STEP_TRAVEL:
            return {
                title: '2. Photo en arrivant',
                progressPercent: 33,
                component: <StepPictureBefore/>
            };
        case enums.qualityInspection.STEP_PICTURE_BEFORE:
            return {
                title: '2. Fournitures éventuelles',
                progressPercent: 44,
                component: <StepQuotation/>
            };
        case enums.qualityInspection.STEP_QUOTATION:
            if (qualityInspection.current_sub_step === enums.qualityInspection.SUB_STEP_ALL_TO_VALIDATE) {
                return {
                    title: '2. Validation devis',
                    progressPercent: 50,
                    component: <SubStepQuotationToValidate/>
                };
            }

            if (qualityInspection.current_sub_step === enums.qualityInspection.SUB_STEP_ALL_VALIDATED) {
                return {
                    title: '2.  Attest. reste à charge',
                    progressPercent: 54,
                    component: <SubStepCertificateClientPriceSignature/>
                };
            }

            if (qualityInspection.current_sub_step === enums.qualityInspection.SUB_STEP_ALL_SIGNATURE
                && intervention.live_payment) {
                return {
                    title: '2.  Empreinte bancaire',
                    progressPercent: 58,
                    component: <SubStepOnlinePayment/>
                };
            }
        case enums.qualityInspection.STEP_CLIENT_SIGNATURE:
            if (qualityInspection.current_sub_step === enums.qualityInspection.SUB_STEP_ALL_TO_VALIDATE) {
                return {
                    title: '3. Validation devis',
                    progressPercent: 62,
                    component: <SubStepQuotationToValidate/>
                };
            }

            if (qualityInspection.current_sub_step === enums.qualityInspection.SUB_STEP_ALL_VALIDATED) {
                return {
                    title: '3. Validation devis',
                    progressPercent: 64,
                    component: <SubStepCertificateClientPriceSignature/>
                };
            }

            if (qualityInspection.current_sub_step === enums.qualityInspection.SUB_STEP_ALL_SIGNATURE
                && intervention.live_payment) {
                return {
                    title: '3. Validation devis',
                    progressPercent: 65,
                    component: <SubStepOnlinePayment/>
                };
            }

            return {
                title: '3. Intervention',
                progressPercent: 66,
                component: <StepWork/>
            };
        case enums.qualityInspection.STEP_WORK:
            return {
                title: '3. Photo après intervention',
                progressPercent: 77,
                component: <StepPictureAfter/>
            };
        case enums.qualityInspection.STEP_PICTURE_AFTER:
            return {
                title: '3. Procès-Verbal',
                progressPercent: 88,
                component: <StepDelivery/>
            };
        case enums.qualityInspection.STEP_DELIVERY:
            return {
                title: '',
                progressPercent: 100,
                component: <Finally/>
            };
    }

    return {
        title: null,
        progressPercent: 10,
        component: null
    };
}

export default QualityInspection;
