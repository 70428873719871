import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    current_step: 0,
    current_sub_step: -1,
    appointment: null,
    quotation_validate: false,
    demo: {
        current_step: 0
    }
}

export const demo = createSlice({
    name: 'demo',
    initialState,
    reducers: {
        update: (state, action) => {
            state[action.payload.property] = action.payload.value;
        }
    },
})

// Action creators are generated for each case reducer function
export const {update} = demo.actions;

export default demo.reducer;
