import {useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import isNull from "../../../mixin/isNull";
import enums from "../../../enum/enums";

export default function ({nextStep}) {
    const demo = useSelector(state => state.demo);
    const signatureHelpButtonInterval = useRef();
    const skipSignatureButtonInterval = useRef();
    const [stepCompleted, setStepCompleted] = useState(false);

    useEffect(() => {
        play();
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(signatureHelpButtonInterval.current);
            clearInterval(skipSignatureButtonInterval.current);
        }
    }, []);

    useEffect(() => {
        if (isNull(demo)) return;

        if (demo.current_step === enums.qualityInspection.STEP_CLIENT_SIGNATURE) {
            nextStep();
        }
    }, [demo]);

    const play = () => {
        setTimeout(() => {
            window.sound.src = "/demo/step-6.1.mp3";
            window.sound.play();
        }, 500);

        setTimeout(() => {
            window.sound.src = "/demo/step-6.2.mp3";
            window.sound.play();
        }, 11500);

        setTimeout(() => {
            window.sound.src = "/demo/step-6.3.mp3";
            window.sound.play();
        }, 19500);

        launchSignatureHelpButton();
        launchSkipSignatureButton();
    }


    const launchSignatureHelpButton = () => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);

            setTimeout(() => {
                signatureHelpButtonInterval.current = setInterval(() => {
                    const signatureHelpButton = document.getElementById("signature-help-button");

                    signatureHelpButton.focus();

                    if (signatureHelpButton.style.background === "" || signatureHelpButton.style.background === "rgb(0, 127, 254)") {
                        signatureHelpButton.style.background = "#eb4435";
                        signatureHelpButton.style.color = "white";
                    } else {
                        signatureHelpButton.style.background = "rgb(0, 127, 254)";
                        signatureHelpButton.style.color = "white";
                    }

                }, 500);
            }, 1000);

            setTimeout(() => {
                clearInterval(signatureHelpButtonInterval.current);

                const signatureHelpButton = document.getElementById("signature-help-button");

                signatureHelpButton.style.background = "rgb(0, 127, 254)";
                signatureHelpButton.style.color = "white";

                setTimeout(() => {
                    signatureHelpButton.style.background = "yellow";

                    setTimeout(() => {
                        signatureHelpButton.click();
                    }, 300);
                }, 1000);
            }, 5000);
        }, 13500);
    }

    const launchSkipSignatureButton = () => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
            const skipSignatureButton = document.getElementById("skip-signature-button");

            skipSignatureButton.focus();

            setTimeout(() => {
                skipSignatureButtonInterval.current = setInterval(() => {
                    const skipSignatureButton = document.getElementById("skip-signature-button");

                    if (skipSignatureButton.style.background === "" || skipSignatureButton.style.background === "rgb(0, 127, 254)") {
                        skipSignatureButton.style.background = "#eb4435";
                        skipSignatureButton.style.color = "white";
                    } else {
                        skipSignatureButton.style.background = "rgb(0, 127, 254)";
                        skipSignatureButton.style.color = "white";
                    }
                }, 500);

                setTimeout(() => {
                    clearInterval(skipSignatureButtonInterval.current);

                    const skipSignatureButton = document.getElementById("skip-signature-button");

                    skipSignatureButton.style.background = "rgb(0, 127, 254)";
                    skipSignatureButton.style.color = "white";

                    setTimeout(() => {
                        skipSignatureButton.style.background = "yellow";

                        setTimeout(() => {
                            skipSignatureButton.click();
                        }, 300);
                    }, 1000);
                }, 5000);
            }, 1000);
        }, 23000);
    }


    if (stepCompleted) return null;

    return (
        <div className="bg-white/30 fixed z-50 h-full w-full max-w-2xl">

        </div>
    );
}