import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import enums from "../../../enum/enums";

export default function ({nextStep}) {
    const demo = useSelector(state => state.demo);
    const [showButton, setShowButton] = useState(true);
    const [stepCompleted, setStepCompleted] = useState(false);
    const letMessageInterval = useRef();
    const demoAppointmentInterval = useRef();
    const sound = useRef();

    useEffect(() => {
        return () => {
            clearInterval(letMessageInterval.current);
            clearInterval(demoAppointmentInterval.current);
        }
    }, []);

    useEffect(() => {
        if (demo.current_step === enums.qualityInspection.STEP_APPOINTMENT) {
            nextStep();
        }
    }, [demo]);

    const play = () => {
        window.sound.src = "/demo/step-1.1.mp3";
        window.sound.play();
        setShowButton(false);

        setTimeout(() => {
            window.sound.src = "/demo/step-1.2.mp3";
            window.sound.play();
        }, 10000);

        setTimeout(() => {
            window.sound.src = "/demo/step-1.3.mp3";
            window.sound.play();
        }, 34000);

        setTimeout(() => {
            window.sound.src = "/demo/step-1.4.mp3";
            window.sound.play();

            setTimeout(() => {
                setStepCompleted(true);
            }, 1000);
        }, 47000);

        launchAppointment();
        launchLetMessage();
    }

    const launchAppointment = () => {
        setTimeout(() => {
            demoAppointmentInterval.current = setInterval(() => {
                const demoAppointment = document.getElementById("demo-appointment-input");

                window.scrollTo(0, document.body.scrollHeight);

                if (demoAppointment.style.background === "" || demoAppointment.style.background === "rgb(0, 127, 254)") {
                    demoAppointment.style.background = "red";
                    demoAppointment.children[0].children[0].style.background = "red";
                } else {
                    demoAppointment.style.background = "#007FFE";
                    demoAppointment.children[0].children[0].style.background = "#007FFE";
                }
            }, 500);

            setTimeout(() => {
                clearInterval(demoAppointmentInterval.current);

                const demoAppointment = document.getElementById("demo-appointment-input");

                demoAppointment.style.background = "#007FFE";
                demoAppointment.children[0].children[0].style.background = "#007FFE";
            }, 4000);
        }, 19000);
    }

    const launchLetMessage = () => {
        window.scrollTo(0, 0);

        setTimeout(() => {
            letMessageInterval.current = setInterval(() => {
                const letMessageButton = document.getElementById("let-message-button");

                if (letMessageButton.style.background === "rgb(255, 237, 213)") {
                    letMessageButton.style.background = "red";
                    letMessageButton.style.color = "white";
                } else {
                    letMessageButton.style.background = "rgb(255, 237, 213)";
                    letMessageButton.style.color = "rgb(249 115 22)";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(letMessageInterval.current);

                const letMessageButton = document.getElementById("let-message-button");

                letMessageButton.style.background = "rgb(255, 237, 213)";
                letMessageButton.style.color = "rgb(249 115 22)";
            }, 5000);

        }, 43000);
    }

    if (stepCompleted) return null;

    return (
        <div
            className={`${showButton ? "bg-white/80" : "bg-white/30"} fixed z-50 h-full w-full max-w-2xl flex justify-center`}>
            {
                showButton
                    ? (
                        <button className="bg-blue-1 h-20 mt-56 px-5 mx-5 rounded-lg text-white" onClick={play}>
                            Montez le son de votre mobile au maximum.
                            <br/>
                            Démarrer la formation
                        </button>
                    )
                    : null
            }

        </div>
    );
}