import axios from "axios";
import {useParams} from "react-router";

const prefix = 'service-emergency';

export default function () {
    const {token} = useParams();

    return {
        async getAllStandardizedItems() {
            if (token === "demo") {
                return [
                    {
                        id: 1,
                        name: "Serrure",
                        price: 100,
                        max_quantity: 3,
                        code: "S00001",
                        enabled: true,
                        work_id: 1

                    }
                ]
            }

            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/${prefix}/application/standardized_items/by/token/${token}`);
                return response.data;
            } catch (e) {
                return null;
            }
        }
    };
}
