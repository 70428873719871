import React from "react";

export default function ({title, callback, loading = false, style = null, fixed = true}) {

    if (loading) {
        return (
            <button
                type="button"
                className={`bottom-0 mb-0 bg-blue-1 w-full max-w-2xl text-white text-center h-16 flex justify-center ${fixed ? "fixed" : ""} ${style}`}
                disabled={true}
                onClick={callback}
            >
                <svg className="animate-spin h-8 w-8 text-white my-auto"
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                            strokeWidth="4"/>
                    <path className="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg>
            </button>
        );
    }

    return (
        <button type="button"
                className={`bottom-0 bg-blue-1 w-full max-w-2xl text-white text-center h-16 ${fixed ? "fixed" : "mt-10"} ${style}`}
                onClick={callback}>
            {title}
        </button>
    );
}
