import React, {useEffect, useRef, useState} from "react";
import Help from "./Help";
import useApi from "../../../../api/useApi";

export default function ({onSigned, onSkip, requestedDocumentType}) {
    const api = useApi();
    const [showHelp, setShowHelp] = useState(false);
    const [disableSkipButton, setDisableSkipButton] = useState(false);
    const interval = useRef(null);


    useEffect(() => {
        interval.current = setInterval(async () => {
            const isSigned = await api.requestedDocument.isSigned(requestedDocumentType);

            if (isSigned) {
                clearInterval(interval.current);
                onSigned();
            }
        }, 5000);

        return () => clearInterval(interval.current);
    }, []);

    return (
        <div className="max-w-2xl">
            <div className="p-1">
                <button
                    className="text-white flex bg-orange-500 justify-center rounded-lg border shadow-lg mt-5 w-full max-w-2xl p-3 text-sm w-full"
                    disabled={true}>
                    <span className="text-base">
                        Signature électronique
                    </span>
                </button>
                <span className="italic">
                    Le sociétaire à reçu un SMS pour signer électroniquement son document
                </span>
                <div className="text-center font-bold text-xl mt-4 animate-pulse text-orange-500">
                    Veuillez patienter ...
                </div>
            </div>
            {
                showHelp
                    ? (
                        <div className="mt-5">
                            <Help/>

                            <button
                                className="mt-5 bg-blue-500 text-white text-center w-full py-3"
                                id="skip-signature-button"
                                disabled={disableSkipButton}
                                onClick={async () => {
                                    setDisableSkipButton(true);
                                    if (await onSkip()) {
                                        setDisableSkipButton(false);
                                    }
                                }}
                            >
                                Passer la signature électronique
                            </button>
                        </div>
                    )
                    : (
                        <div className="mt-10">
                            <button
                                className="bg-blue-500 text-white text-center w-full py-3"
                                id="signature-help-button"
                                onClick={() => setShowHelp(true)}
                            >
                                Le sociétaire rencontre un problème pour signer son document
                            </button>
                        </div>
                    )
            }
        </div>
    );
}
