import Compress from 'compress.js';

export default {
    uuid: () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    },
    priceFormatter(value) {
        if (value === null) {
            return;
        }

        value = value.toString();

        if (value.includes('.')) {
            let sections = value.split('.');

            return sections[0] + "," + sections[1].substr(0, 2);
        }

        return value + ",00";
    },
    isAuthenticate(accessToken) {
        if (accessToken === null) {
            return false;
        }

        const decodedAccessToken = this.decodeAccessToken(accessToken);

        // For development
        if (decodedAccessToken.realm_access === undefined) {
            return false;
        }

        if (!decodedAccessToken.realm_access.roles.includes('ROLE_FAIRFAIR_EMERGENCY_ARTISAN')
            || !decodedAccessToken.realm_access.roles.includes('ROLE_FAIRFAIR_USER_ARTISAN')) {
            return false;
        }

        return true;
    },
    decodeAccessToken(token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    },
    dateFormatter(date) {
        let reference = null;

        if (date instanceof Date) {
            reference = date;
        } else {
            reference = new Date(date);
        }

        return `${reference.toLocaleDateString("fr-Fr")} à ${reference.toLocaleTimeString("fr").split(':')[0]}h${reference.toLocaleTimeString("fr").split(':')[1]}`
    },
    async compressFile(file) {
        const compress = new Compress();
        const data = await compress.compress([file], {
            size: 1,
            quality: .5,
            rotate: false
        });
        return data;
    },
    phoneFormatter(phone) {
        let formatedPhoneNumber = phone;
        if(phone) {
            if(phone.includes('+33')) {
                formatedPhoneNumber = phone.replace(/^((\+)33)/, "0");
            }
            formatedPhoneNumber = formatedPhoneNumber.replace(/(.{2})/g, '$1 ');
            formatedPhoneNumber = formatedPhoneNumber.slice(0, -1);
        }
        return formatedPhoneNumber;
    }
}
