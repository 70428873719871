import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import isNull from "../../../mixin/isNull";
import enums from "../../../enum/enums";

export default function ({nextStep}) {
    const demo = useSelector(state => state.demo);
    const infoAppointmentInterval = useRef();
    const locationLinkInterval = useRef();
    const menuInterval = useRef();
    const openMenuClientPhone1Interval = useRef();
    const [stepCompleted, setStepCompleted] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            play();
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(infoAppointmentInterval.current);
            clearInterval(locationLinkInterval.current);
            clearInterval(menuInterval.current);
            clearInterval(openMenuClientPhone1Interval.current);
        }
    }, []);

    useEffect(() => {
        if (isNull(demo)) return;

        if (demo.current_step === enums.qualityInspection.STEP_TRAVEL) {
            nextStep();
        }
    }, [demo]);

    const play = () => {
        window.sound.src = "/demo/step-2.1.mp3";
        window.sound.play();

        setTimeout(() => {
            window.sound.src = "/demo/step-2.2.mp3";
            window.sound.play();
        }, 13000);

        setTimeout(() => {
            window.sound.src = "/demo/step-2.3.mp3";
            window.sound.play();
        }, 22000);

        setTimeout(() => {
            window.sound.src = "/demo/step-2.4.mp3";
            window.sound.play();
        }, 28000);

        setTimeout(() => {
            window.sound.src = "/demo/step-2.5.mp3";
            window.sound.play();
        }, 31000);

        setTimeout(() => {
            window.sound.src = "/demo/step-2.6.mp3";
            window.sound.play();

            setTimeout(() => {
                setStepCompleted(true);
            }, 1000);
        }, 42000);

        launchInfoAppointment();
        launchLocationLink();
        launchMenu();
        launchOpenMenuClientPhone1();
    }

    const launchInfoAppointment = () => {
        setTimeout(() => {
            infoAppointmentInterval.current = setInterval(() => {
                const infoAppointment = document.getElementById("info-appointment");

                if (infoAppointment.style.background === "" || infoAppointment.style.background === "rgb(234, 242, 255)") {
                    infoAppointment.style.background = "red";
                    infoAppointment.style.color = "white";
                } else {
                    infoAppointment.style.background = "#EAF2FF";
                    infoAppointment.style.color = "rgb(0, 127, 254)";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(infoAppointmentInterval.current);

                const infoAppointment = document.getElementById("info-appointment");

                infoAppointment.style.background = "#EAF2FF";
                infoAppointment.style.color = "rgb(0, 127, 254)";
            }, 4000);

        }, 7000);
    }

    const launchLocationLink = () => {
        setTimeout(() => {
            locationLinkInterval.current = setInterval(() => {
                const locationLink = document.getElementById("location-link");

                if (locationLink.style.background === "" || locationLink.style.background === "rgb(234, 242, 255)") {
                    locationLink.style.background = "red";
                    locationLink.style.color = "white";
                } else {
                    locationLink.style.background = "rgb(234, 242, 255)";
                    locationLink.style.color = "rgb(0, 127, 254)";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(locationLinkInterval.current);

                const locationLink = document.getElementById("location-link");

                locationLink.style.background = "rgb(234, 242, 255)";
                locationLink.style.color = "rgb(0, 127, 254)";
            }, 5000);

        }, 16000);
    }


    const launchMenu = () => {
        setTimeout(() => {
            menuInterval.current = setInterval(() => {
                const menu = document.getElementById("menu");

                if (menu.style.background === "" || menu.style.background === "rgb(234, 242, 255)") {
                    menu.style.background = "red";
                    menu.style.color = "white";
                } else {
                    menu.style.background = "rgb(234, 242, 255)";
                    menu.style.color = "rgb(0, 127, 254)";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(menuInterval.current);

                const menu = document.getElementById("menu");

                menu.style.background = "rgb(234, 242, 255)";
                menu.style.color = "rgb(0, 127, 254)";
            }, 3000);

        }, 25000);
    }


    const launchOpenMenuClientPhone1 = () => {
        setTimeout(() => {
            const menu = document.getElementById("menu");
            menu.click();

            openMenuClientPhone1Interval.current = setInterval(() => {
                let openMenuClientPhone1 = document.getElementById("open-menu-client-phone-1");

                if (isNull(openMenuClientPhone1)) {
                    menu.click();

                    openMenuClientPhone1 = document.getElementById("open-menu-client-phone-1");
                }

                if (openMenuClientPhone1.style.background === "" || openMenuClientPhone1.style.background === "rgb(0, 127, 254)") {
                    openMenuClientPhone1.style.background = "red";
                    openMenuClientPhone1.style.color = "white";
                } else {
                    openMenuClientPhone1.style.background = "rgb(0, 127, 254)";
                    openMenuClientPhone1.style.color = "white";
                }

            }, 500);

            setTimeout(() => {
                clearInterval(openMenuClientPhone1Interval.current);

                let openMenuClientPhone1 = document.getElementById("open-menu-client-phone-1");

                if (isNull(openMenuClientPhone1)) {
                    menu.click();

                    openMenuClientPhone1 = document.getElementById("open-menu-client-phone-1");
                }

                openMenuClientPhone1.style.background = "rgb(0, 127, 254)";
                openMenuClientPhone1.style.color = "white";
                menu.click();
            }, 3000);
        }, 28000);
    }

    if (stepCompleted) return null;

    return (
        <div className="bg-white/30 fixed z-50 h-full w-full max-w-2xl">

        </div>
    );
}